import React, {useEffect} from 'react';
import grayCheck from "../assets/images/gray-check.png";
import StickyFooter from "./StickyFooter";
import {urls} from "../utils/routes";
import {useSelector} from "react-redux";
import {isNotEmpty} from "../utils/Helper";


const StartChatbotScreen = ()=> {
    const { candidateInterest, candidateInterestDate, jobCandidateMapping } = useSelector(state=>state);
    useEffect(()=>{
        if(!candidateInterest && !isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.CONSENT}`
        }
        else if(!candidateInterest && isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.DECLINE_THANKYOU}`
        }
        else if(jobCandidateMapping.chatbotStatus === "Complete"){
            document.location = `#${urls.UPLOAD_RESUME}`
        }
    }, [candidateInterest, candidateInterestDate]);

    return (
        <div className="chatbot-container start-chatbot">
            <h4>That’s awesome!</h4>
            <p>Good to know that you are excited about this role. First things, first. Here is what you can expect.</p>
            <div className="key-points-list">

                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span className="key-text">You will answer mostly <b>multiple choice questions.</b></span>
                </div>
                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span className="key-text">Just <b>tap on the choices</b> presented. It’s that easy.</span>
                </div>
                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span className="key-text">There are sections like <b>HR, Job Role,</b> etc</span>
                </div>
                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span className="key-text">This usually takes about <b>5-7 minutes</b> but the time may vary depending on the questions required for the role.</span>
                </div>
                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span
                        className="key-text">Please note that this is not an assessment. It simply <b>creates a profile</b> of your work, so just go ahead and answer the questions naturally.</span>
                </div>
                <div className="key-point">
                    <span className="grey-crl"><img src={grayCheck} width="12px" alt="checkmark"/></span>
                    <span className="key-text">The <b>progress bar</b> would indicate the progress you are making.</span>
                </div>

                <p>You are encouraged to finish the entire profile in one go. However, if something happens and you are
                    unable to do so, simply click on the SMS or Email link to start from where you left earlier.</p>

            </div>
            <StickyFooter btnText={"I am ready to begin"} onClick={() => document.location = `#${urls.CHATBOT}`}/>
        </div>
    )
};

export default StartChatbotScreen;