export function isNotEmpty(value) {
  return (value !== null && value !== undefined && value !== "");
}

export function StringToHtml(htmlString, tag) {
  if (isNotEmpty(tag)) {
    if (tag === "p") {
      return (
        <p dangerouslySetInnerHTML={{ __html: htmlString }} />
      )
    }
  } else {
    return (
      <span dangerouslySetInnerHTML={{ __html: htmlString }} />
    )
  }
}

export function isValidSalary(data) {
  let notError = false;
  if (/^[0-9]*\.?[0-9]{0,2}$/g.test(data)) {
    notError = true
  }
  return notError
}

export function isValidSalaryForOk(data) {
  let notError = false;
  if (/^[0-9]*\.?(?=[0-9])[0-9]{0,2}$/g.test(data)) {
    notError = true
  }
  return notError
}

export const removeHTMLTags = (htmlString) => {
  let parentElement = document.createElement("div");
  parentElement.innerHTML = htmlString.trim();

  return decodeURIComponent(parentElement.innerText);
};

export const getUrlLinkType = (params) => {
  const urlParams = new URLSearchParams(params);
  return urlParams.has("type") ? urlParams.get("type") : "emailLink";
};

export const deepClone = (params) => {
  return JSON.parse(JSON.stringify(params));
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};