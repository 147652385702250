import React from 'react'
import CategoryCard from "../common/categoryCard";

const CategorySelection = ({categories, onCategorySelect}) => {
    const completeArr = Object.keys(categories).map(category => {
       return categories[category]?.isCompleted
    })
    const complete = completeArr.every(val => val===false)
    return (
        <div >
            <div style={{marginTop: '40px'}} className="question-container"> 
            {complete ?
                <>
                <h1 className="question">
                    This section is to seek information about your job role and skills!
                </h1>
                <p style={{fontSize: '16px', marginRight: '20px'}} className="objective">
                    The Job Role section may be divided into several cards. Tap on any card to begin answering the questions.
                    Once you finish answering all the questions in a card, you will come back to this screen.
                    Continue till you have completed all cards.
                </p>
                </> :
                 <h1 className="question">
                    Now choose any incomplete card below. Lets begin!
                </h1>
            }
            </div>
            <div style={{ marginLeft: '-20px',display:'flex', flexWrap:'wrap', justifyContent: "space-around" }} className="">
                {
                    Object.keys(categories).map((category, index)=>(
                        <CategoryCard key={`${category}_${index}`} category={category} questionCount={categories[category]?.questionCount} complete={categories[category]?.isCompleted} onClick = {() => !categories[category]?.isCompleted?onCategorySelect(category):null}/>
                    ))
                }
            </div>
        </div>
    )
};

export default CategorySelection
