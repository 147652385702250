import {useState} from 'react';
import {func} from "prop-types";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import './calendar.css'
import OKButton from "../../OKButton";
import {isNotEmpty} from "../../../utils/Helper";
import { renderAnswerTypes } from '../../../utils/Constant';

const CalendarInput = ({ handleSubmit, questionType }) => {
    const [value, setValue] = useState(new Date());

    const handleMaxDate = () => {
      if (questionType === renderAnswerTypes.PAST_CALENDAR) {
        return new Date();
      }
      return null;
    };

    const handleMinDate = () => {
      if (questionType === renderAnswerTypes.FUTURE_CALENDAR) {
        return new Date();
      }
      return null;
    };
      
    return (
        <div>
            <div className={"selected-date"}>{moment(value).format("ddd, MMM DD")}</div>
            <Calendar
                onChange={setValue}
                value={value}
                className={" custom-calendar-style "}
                maxDate={handleMaxDate()}
                minDate={handleMinDate()}
            />
            <br />
            <OKButton onClick={()=>{handleSubmit(moment(value).format("DD MMM YYYY")); setValue(new Date());}} disabled={!isNotEmpty(value)} />
        </div>
    )
};

export default CalendarInput

CalendarInput.propTypes = {
  handleSubmit: func.isRequired,
};