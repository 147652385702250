import React, {useEffect} from 'react';
import PoweredByLogo from "./common/PoweredByLogo";
import ButtonOrange from "./common/ButtonOrange";
import litmusLogo from "../assets/images/logo-litmusBlox.svg";
import {useSelector} from 'react-redux';
import {isNotEmpty} from "../utils/Helper";
import {urls} from "../utils/routes";

const Home = ()=>{
    const {jobDetail, jobCandidateMapping, chatbotContent, candidateInterest, candidateInterestDate} = useSelector(state=>state);

    const company = isNotEmpty(jobDetail)?jobDetail.companyId:null;
    const candidateName = isNotEmpty(jobCandidateMapping)?jobCandidateMapping.displayName:"";

    useEffect(()=>{
        if(jobCandidateMapping.chatbotStatus==="Complete" && candidateInterest){
            document.location = `#${urls.UPLOAD_RESUME}`
        }
        else if(!(jobCandidateMapping.chatbotStatus==="Complete") && candidateInterest){
            document.location = `#${urls.START_CHATBOT_CONSENT}`
        }
        else if(!(jobCandidateMapping.chatbotStatus==="Complete") && (!candidateInterest && isNotEmpty(candidateInterestDate))){
            document.location = `#${urls.DECLINE_THANKYOU}`
        }
    }, []);

    return(
        <div className="chatbot-container">
            <div className="company-logo">
                <img src={ isNotEmpty(company.logo)?(process.env.REACT_APP_BASE + company?.logo): litmusLogo } width={200} alt={"Logo"}/>
            </div>
            <div className="home-text">
                {
                    isNotEmpty(chatbotContent) && isNotEmpty(chatbotContent.homeText)?
                          <p>{chatbotContent.homeText}</p>
                          :
                    <p>
                        Hello <span className="c-name">{candidateName}</span> &#x1f44b;<br/>
                        <b>{company.companyName}</b> has reviewed your profile and is considering you for the <b>{jobDetail.jobTitle}</b> position. Click here to know more and apply.
                    </p>
                }

            </div>
            <ButtonOrange btnText={"I wish to know more"} onClick={()=> document.location = `#${urls.CONSENT}`}/>
            <PoweredByLogo/>
        </div>
    )
};
export default Home;