import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isNotEmpty, StringToHtml } from "../../utils/Helper";
import { urls } from "../../utils/routes";
import { uChatbotPhases } from "../../utils/UChatbotConstants";
import ThankYou from "../common/ThankYou";

const UChatbotThankYouWrapper = () => {
  const {
    candidateInterest,
    candidateInterestDate,
    uChatbotPhase,
    uChatbotDetails,
    isResumeUploaded,
  } = useSelector((state) => state);
  const { companyName, candidateFirstName, candidateLastName, questionToAsk } =
    uChatbotDetails;

  useEffect(() => {
    if (!(uChatbotPhase === uChatbotPhases.COMPLETE) && candidateInterest) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (
      !(uChatbotPhase === uChatbotPhases.COMPLETE) &&
      !candidateInterest &&
      !isNotEmpty(candidateInterestDate)
    ) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.DECLINE_THANKYOU}`;
    }
  }, [uChatbotPhase, candidateInterest, candidateInterestDate]);

  const heading = (
    <div className="question" style={{ marginBottom: "8px" }}>
      {isResumeUploaded ? "Hooray " : "No problem, "}
      <span style={{ textTransform: "capitalize" }}>
        {`${candidateFirstName} ${candidateLastName}!`}
      </span>
    </div>
  );

  const goodLuckText = (
    <div>
      <p className="resume-page-text">
        {/* {` If your profile is a match for any jobs at ${companyName}, we will be in touch
        with you.`} */}
        {StringToHtml(
          questionToAsk?.successfulCompletion.replace(
            "$companyName",
            companyName
          )
        )}
      </p>
      <p className="resume-page-text">Good luck!</p>{" "}
    </div>
  );

  const successText = (
    <p className="resume-page-text">
      {"Your application is successfully submitted."}
    </p>
  );
  return (
    <ThankYou
      companyName={companyName}
      successText={successText}
      goodLuckText={goodLuckText}
      heading={heading}
    />
  );
};

export default UChatbotThankYouWrapper;
