import {
    SET_ANSWER,
    SET_ITEM,
    SET_MULTILEVEL_OPTIONS,
    SET_OPTIONS,
    SET_QUESTION,
    SET_QUESTION_ID,
    SET_QUESTION_TYPE,
    SET_QUICK_ANSWER,
    SET_LINK_TYPE
} from '../actions/ChatbotActions'

export const answer = (answer = {}, action) => {
  switch (action.type) {
      case SET_ANSWER:
          return {...answer, ...action.answer};
      default:
          return answer;
  }
};

export const quickAnswer = (answer = {}, action) => {
    switch (action.type) {
        case SET_QUICK_ANSWER:
            return {...answer, ...action.answer};
        default:
            return answer;
    }
};

export const item = (item = [], action) => {
    switch (action.type) {
        case SET_ITEM:
            return action.item;
        default:
            return item
    }
};

export const questionId = (questionId = null, action) => {
    switch (action.type) {
        case SET_QUESTION_ID:
            return action.questionId;
        default:
            return questionId;
    }
};

export const question = (question = null, action) => {
    switch (action.type) {
        case SET_QUESTION:
            return action.question;
        default:
            return question
    }
};

export const options = (options = [], action) => {
    switch (action.type) {
        case SET_OPTIONS:
            return action.options;
        default:
            return options;
    }
};

export const multiLevelOptions = (multiLevelOptions = [], action) => {
    switch (action.type) {
        case SET_MULTILEVEL_OPTIONS:
            return action.multiLevelOptions;
        default:
            return multiLevelOptions
    }
};

export const questionType = (questionType = null, action) => {
    switch(action.type){
        case SET_QUESTION_TYPE:
            return action.questionType;
        default:
            return questionType
    }
};

export const linkType = (linkType = null, action) => {
    switch(action.type){
        case SET_LINK_TYPE:
            return action.linkType;
        default:
            return linkType
    }
};