import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isNotEmpty, StringToHtml } from "../../utils/Helper";
import { urls } from "../../utils/routes";
import ButtonOrange from "../common/ButtonOrange";
import PoweredByLogo from "../common/PoweredByLogo";
import Index from "../answerTypes/RadioButton/index";
import OKButton from "../OKButton";
import { setDeclineReason } from "../../actions/DeclineReasonActions";
import { uChatbotSubmitInterest } from "../../services/InterestService";

const UniversalChatbotDecline = () => {
  const dispatch = useDispatch();
  const {
    uChatbotDetails,
    candidateInterest,
    declineReason,
    notInterestedReasonsUniversal,
    uChatbotPhase,
    candidateInterestDate,
  } = useSelector((state) => state);

  const {
    candidateFirstName,
    candidateLastName,
    questionToAsk
  } = uChatbotDetails;

  const [unsuccessfulCompletion, setUnsuccessfulCompletion] = useState("")

  useEffect(() => {
    if (candidateInterest && uChatbotPhase !== "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    } else if (candidateInterest && uChatbotPhase === "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    }
  }, [candidateInterest, candidateInterestDate, uChatbotPhase]);

  useEffect(() => {
    let string = isNotEmpty(questionToAsk?.unsuccessfulCompletion) ? questionToAsk?.unsuccessfulCompletion : ""
    let updatedString =
      string.replace("$candidateName, I understand!", `<h4><b>${candidateFirstName} ${candidateLastName}, I understand!</b></h4>`)
        .replace("\\n", `<br />`)

    setUnsuccessfulCompletion(updatedString)
  }, []);

  return (
    <div className="chatbot-container Hr-selection-screen bottom-container">
      <div className="decline-text-universal">
        {StringToHtml(unsuccessfulCompletion, "p")}
      </div>
      {
        isNotEmpty(notInterestedReasonsUniversal) && Object.keys(notInterestedReasonsUniversal).map((reasonId, index) => (
          <Index
            key={`${reasonId}_${index}`}
            value={reasonId}
            checked={reasonId === declineReason}
            handleChange={() => dispatch(setDeclineReason(reasonId))}
            label={notInterestedReasonsUniversal[reasonId]}
          />
        ))
      }
      <OKButton
        disabled={!isNotEmpty(declineReason)}
        btnText={"Submit"}
        onClick={() => uChatbotSubmitInterest()}
      />
      <PoweredByLogo />
    </div>
  );
};
export default UniversalChatbotDecline;
