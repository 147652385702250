import {
    SET_CHATBOT_CONTENT,
    SET_CHATBOT_ERROR_MESSAGE,
    SET_CREATED_BY, SET_HR_QUESTIONS, SET_JCM_COMMUNICATION_DETAILS,
    SET_JOB_CANDIDATE_MAPPING,
    SET_JOB_DETAIL
} from '../../actions/DataAction';

export const jobDetail = (jobDetail = {}, action) => {
    switch (action.type) {
        case SET_JOB_DETAIL:
            return { ...jobDetail, ...action.jobDetail };
        default:
            return jobDetail
    }
};

export const jobCandidateMapping = (jobCandidateMapping = {}, action) =>{
    switch (action.type) {
        case SET_JOB_CANDIDATE_MAPPING:
            return { ...jobCandidateMapping, ...action.jobCandidateMapping };
        default:
            return jobCandidateMapping;
    }
};

export const createdBy = (createdBy = null, action) => {
    switch (action.type) {
        case SET_CREATED_BY:
            return action.createdBy;
        default:
                return createdBy
    }
};

export const jcmCommunicationDetails = (jcmCommunicationDetails = {}, action) => {
    switch (action.type) {
        case SET_JCM_COMMUNICATION_DETAILS:
            return {...jcmCommunicationDetails, ...action.jcmCommunicationDetails};
        default:
            return jcmCommunicationDetails;
    }
};

export const hrQuestions = (hrQuestions = [], action) => {
    switch(action.type){
        case SET_HR_QUESTIONS:
            return action.hrQuestions;
        default:
            return hrQuestions
    }
};

export const chatbotContent = (chatbotContent = {}, action) => {
    switch (action.type) {
        case SET_CHATBOT_CONTENT:
            return { ...chatbotContent, ...action.chatbotContent };
        default:
            return chatbotContent
    }
};

export const chatbotErrorMessage = (chatbotErrorMessage = "", action) => {
    switch (action.type) {
        case SET_CHATBOT_ERROR_MESSAGE:
        return action.chatbotErrorMessage;
        default:
        return chatbotErrorMessage;
    }
};