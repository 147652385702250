import { string, number, bool, object, shape, arrayOf } from "prop-types";

export const questionObjType = shape({
  question: string,
  ansType: string,
  anyN: number,
  level: string,
  areaOrDomain: string,
  screeningQuestions: bool,
  multiLevelOptions: object,
  id: number,
  options: arrayOf(string),
  customizeQuestion: string,
});
