import {useState} from "react";
import PropTypes from "prop-types";
import OKButton from "../OKButton";
import {
  isNotEmpty,
  isValidSalary,
  isValidSalaryForOk,
} from "../../utils/Helper";
import { useSelector } from "react-redux";
import {renderAnswerTypes} from "../../utils/Constant";
import { UChatbotQs } from "../../utils/UChatbotConstants";

const TextInput = ({handleSubmit, questionType, tag}) => {
    const {jobDetail} = useSelector(state => state);
    const [inputValue, setInputValue] = useState("");  
    
    const isNonINRSlider =()=> {
        if(jobDetail.currency !== "INR" && questionType === renderAnswerTypes.SLIDER ){
            return true;
        } else return false;
    }

    const onChange = (e) => {
        if(isNonINRSlider() ) {
            if(isValidSalary(e.target.value)){
                setInputValue(e.target.value)
            }
        } else if (isNotEmpty(tag) && tag === UChatbotQs.OTHER_SKILLS_TAG) {
            let validatedInputValue =  e.target.value.replace(/[^a-zA-Z0-9.#+, ]/g, "");
            if (validatedInputValue.length === 1) {
              validatedInputValue = validatedInputValue.trim();
            }
            setInputValue(validatedInputValue);
        } else {
            setInputValue(e.target.value)
        }
    }    

    const isDisabled = () => {
        let disabledFlag = true;
        if(isNonINRSlider() ) {
            disabledFlag = isNotEmpty(inputValue) ? inputValue.replace(/ /g, "").length === 0 ? true : false : true; 
            return (disabledFlag || !isValidSalaryForOk(inputValue))
        } else{
            disabledFlag = isNotEmpty(inputValue) ? inputValue.replace(/ /g, "").length === 0 ? true : false : true;
            return disabledFlag
        }
    }

      return (
        <div style={{width: "290px",}}>
            <div style={{display:"flex"}}>
            <input
                onChange={(e) => onChange(e)}
                value={inputValue}
                style={ isNonINRSlider() ? 
                    { 
                        width: "290px",
                        border: "none",
                        borderBottom: "1px solid #33475B",
                        outline: "none",
                        color: "#000000",
                        paddingBottom: "5px",
                        letterSpacing: "0px",
                        marginBottom:"20px",
                        textAlign: "Right" 
                    } 
                    : 
                    {
                        width: "290px",
                        border: "none",
                        borderBottom: "1px solid #33475B",
                        outline: "none",
                        color: "#000000",
                        paddingBottom: "5px",
                        letterSpacing: "0px",
                        marginBottom:"20px",
                        textAlign:"left"
                    }}
                type="text"
                maxLength={isNonINRSlider() ? "15":"300"}
            />
            <>
            {isNonINRSlider() && 
                <div style={{marginLeft:"5px"}}>
                    {jobDetail.currencyUnit}
                </div>
            }
            </>
            </div>
            <p style={{marginTop: '-20px',textAlign: 'right'}}>{inputValue.length}/{isNonINRSlider() ? 15 : 300}</p>
            <br />
            <OKButton onClick={()=>{handleSubmit(inputValue); setInputValue(""); }}
             disabled={isDisabled()}/>
        </div>
    );
};

export default TextInput;

TextInput.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    questionType:PropTypes.string,
}
