import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { isResumeUploaded } from "../../actions/ResumeAction";
import { handleResumeUpload } from "../../services/DataService";
import { urls } from "../../utils/routes";
import PoweredByLogo from "./PoweredByLogo";
import OKButton from "../OKButton";
import resumeIcon from "../../assets/images/resume.png";
import { useSelector } from "react-redux";
import { StringToHtml } from "../../utils/Helper";

const ResumeUpload = ({ candidateName, companyName }) => {
  const dispatch = useDispatch();
  const { isUniversalChatbot, uChatbotDetails } = useSelector((state) => state);
  const { questionToAsk } = uChatbotDetails;

  const hiddenFileInput = useRef(null);

  const [showPrompt, setShowPrompt] = useState(true);
  const [file, setFile] = useState(null);
  const [isError, setIsError] = useState(false);
  const [resumeUpload, setResumeUpload] = useState("");

  useEffect(() => {
    const allowedFile = ["doc", "docx", "pdf"];
    if (!file) return;
    const fileExtension = file.name.split(".").pop();
    if (!allowedFile.includes(fileExtension)) {
      setIsError(true);
      setFile(null);
    } else setIsError(false);
  }, [file, isError]);

  useEffect(() => {
    let string = questionToAsk?.resumeUpload
      .replace("Thanks $candidateName", `<b>Thanks ${candidateName}</b><br />`)
      .replace("$companyName", `${companyName}`)
    setResumeUpload(string)
  }, [questionToAsk]);

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    setFile(fileUploaded);
  };

  return (
    <div className={"chatbot-container"}>
      {showPrompt ? (
        <div
          className="question"
          style={{ letterSpacing: "0px", position: "absolute", bottom: "19px" }}
        >
          <div className="resume-upload-text">
            {
              StringToHtml(resumeUpload, "p")
            }
          </div>
          <div
            style={{ fontSize: "16px", color: "#464646", marginBottom: "34px" }}
          >
            Do you wish to upload your latest resume? It’s optional, but could
            help the HR team understand your profile better.
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OKButton
              onClick={() => setShowPrompt(false)}
              disabled={false}
              btnText={"Upload"}
            />

            <span
              style={{
                cursor: "pointer",
                marginLeft: "34px",
                color: "#33475B",
                fontSize: "16px",
              }}
              onClick={() => {
                dispatch(isResumeUploaded(false));
                document.location = isUniversalChatbot
                  ? `#${urls.UNIVERSAL_CHATBOT_THANKYOU}`
                  : `#${urls.THANKYOU}`;
              }}
            >
              skip
            </span>
          </div>
        </div>
      ) : (
        <div
          className="question"
          style={{ letterSpacing: "0px", position: "absolute", bottom: "19px" }}
        >
          <span>Upload your Resume here.</span>
          <div style={{ fontSize: "16px" }}>
            <span>Then Click on submit button</span>
          </div>
          <br />
          <div style={{ width: "100%", display: "flex" }}>
            <div
              onClick={() => hiddenFileInput.current.click()}
              style={{
                height: "314px",
                width: "298px",
                transform: "matrix(1, 0, 0, 1, 0, 0)",
                boxShadow: "0px 5px 11px #0000005C",
                opacity: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <img src={resumeIcon} alt="resume icon" />
              <br />
              <h6 style={{ color: isError && "red" }}>
                <span style={{ fontSize: "13px" }}>
                  {file ? (
                    <div
                      style={{
                        maxWidth: "250px",
                        overflowWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      {file.name}
                    </div>
                  ) : (
                    "Supported file formats are doc,docx,pdf"
                  )}
                </span>
              </h6>
            </div>
          </div>
          <br />
          <OKButton
            btnText={"Submit Application"}
            disabled={isError || file === null}
            onClick={() => handleResumeUpload(file)}
          />
        </div>
      )}
      <PoweredByLogo />
    </div>
  );
};

export default ResumeUpload;
