import { getValue, setValue } from "./CookieService"
import { getAjaxCall, postAjaxCallHybrid } from "./AjaxService";
import { getUrlLinkType, isNotEmpty } from "../utils/Helper";
import { getMasterData } from "./MasterDataService";
import { apiUrls } from "./ApiUrls";
import store from '../utils/Store'
import {
  receiveInterviewConfirmation,
  receiveInterviewData,
  setChatbotContent,
  setChatbotErrorMessage,
  setCreatedBy,
  setHrQuestions,
  setJcmCommunicationDetails,
  setJobCandidateMappping,
  setJobDetail,
  setUuid
} from "../actions/DataAction";
import { showLoader } from "../actions/LoaderAction";
import setCandidateInterest, {
  setCandidateInterestDate,
  setCandidateNotInterestedReason,
  setCandidateNotInterestedReasonUniversal
} from "../actions/InterestAction";
import { setAnswer, setQuickAnswer, setLinkType } from "../actions/ChatbotActions";
import { urls } from "../utils/routes";
import { isResumeUploaded } from "../actions/ResumeAction";
import { masterDataKeys, uuidMatcher } from "../utils/Constant";
import {
  setUChatbotAnswers,
  setUChatbotDetails,
  setUChatbotPhase,
  setUChatbotQuestions,
} from "../actions/UChatbotDataActions";
import { uChatbotPhases } from "../utils/UChatbotConstants";

const {
  CANDIDATE_NOT_INTERESTED_REASON,
  CANDIDATE_NOT_INTERESTED_REASON_UNIVERSAL,
  INTERVIEW_CONFIRMATION
} = masterDataKeys;

export const getCandidateNotInterestedReasons = () => {
  getMasterData(false, [
    CANDIDATE_NOT_INTERESTED_REASON,
    CANDIDATE_NOT_INTERESTED_REASON_UNIVERSAL
  ], (data, error) => {
    if (null === error && data !== null) {
      store.dispatch(setCandidateNotInterestedReason(data.candidateNotInterestedReason));
      store.dispatch(setCandidateNotInterestedReasonUniversal(data.campaignNotInterestedReason));
    }
    else {
      store.dispatch(setCandidateNotInterestedReason({}));
      store.dispatch(setCandidateNotInterestedReasonUniversal({}));
    }
  })
};

//write function to fetch HR questions and update state variable
export const getChatbotData = (hash) => {
  let uuidHash = '';
  let urlLinkType = 'emailLink';

  if (hash.includes('?')) {
    let getParams = '';
    [uuidHash, getParams] = hash.split("?");
    urlLinkType = getUrlLinkType(getParams)
  }
  else {
    uuidHash = hash;
  }

  const uuid = extractUuid(uuidHash);

  const { notInterestedReasons } = store.getState;

  const uuidFromCookie = getValue('uuid');

  if (!isNotEmpty(uuid) && !isNotEmpty(uuidFromCookie)) {
    document.location = '#/error'
  }
  else if (!isNotEmpty(uuidFromCookie) && isNotEmpty(uuid)) {
    setValue('uuid', uuid);
  }
  else if (isNotEmpty(uuidFromCookie) && isNotEmpty(uuid) && uuidFromCookie !== uuid) {
    setValue('uuid', uuid)
  }
  const uuidToUse = isNotEmpty(uuid) ? uuid : isNotEmpty(uuidFromCookie) ? uuidFromCookie : '';

  store.dispatch(setLinkType(urlLinkType));
  store.dispatch(setUuid(uuidToUse));
  store.dispatch(showLoader(true));

  if (!isNotEmpty(notInterestedReasons) || Object.keys(notInterestedReasons).length === 0) {
    getCandidateNotInterestedReasons();
  }

  let apiUrl = apiUrls.chatbotDetails + "?uuid=" + uuidToUse;
  getAjaxCall(apiUrl, function (data, error) {
    if (error == null) {
      const jcmCommunicationDetails = data.jobCandidateMapping.jcmCommunicationDetails;
      jcmCommunicationDetails.chatCompleteFlag = isNotEmpty(data.jobCandidateMapping) && data.jobCandidateMapping.chatbotStatus === "Complete";
      let answer = {};
      if (isNotEmpty(data.jobCandidateMapping.candidateChatbotResponse)) {
        Object.keys(data.jobCandidateMapping.candidateChatbotResponse).forEach(key => {
          answer[key] = data.jobCandidateMapping.candidateChatbotResponse[key].split(process.env.ARRAY_JOINER)
        })
      }
      let jobDetail = data.jobCandidateMapping?.job || {}
      if (!isNotEmpty(jobDetail.jobSkillsAttributesList)) {
        jobDetail["jobSkillsAttributesList"] = []
      }
      const quickAnswer = data.jobCandidateMapping?.candidateQuickQuestionResponse || {};
      store.dispatch(setQuickAnswer(isNotEmpty(quickAnswer) && typeof quickAnswer === "string" ? JSON.parse(quickAnswer) : {}));
      store.dispatch(setAnswer(answer));
      store.dispatch(setJobDetail(jobDetail));
      store.dispatch(setJobCandidateMappping(data.jobCandidateMapping));
      store.dispatch(setCreatedBy(data.jobCandidateMapping?.createdBy?.displayName || ""));
      store.dispatch(setCandidateInterest(data.jobCandidateMapping.candidateInterest));
      store.dispatch(setCandidateInterestDate(data.jobCandidateMapping?.candidateInterestDate || null));
      store.dispatch(setJcmCommunicationDetails(jcmCommunicationDetails || {}));
      store.dispatch(setHrQuestions(data.jobCandidateMapping?.job?.jobScreeningQuestionsList || []));
      store.dispatch(setChatbotContent(data.chatbotContent || {}));
    } else {
      store.dispatch(setChatbotErrorMessage(error?.message));
    }
    store.dispatch(showLoader(false))
  })
};

export const getInterviewData = (hash) => {

  const { interviewConfirmation, interviewDetail } = store.getState();
  let uuidHash = "";
  if(hash.includes("?")){
    uuidHash = extractUuid(hash.split("?")[0]);
  }else{
    uuidHash = hash;
  }
  const uuid = extractUuid(uuidHash);
  const uuidFromCookie = getValue('interviewUuid');

  if (!isNotEmpty(uuidFromCookie) && isNotEmpty(uuid)) {
    setValue('interviewUuid', uuid);
  }
  else if (isNotEmpty(uuidFromCookie) && isNotEmpty(uuid) && uuidFromCookie !== uuid) {
    setValue('interviewUuid', uuid)
  }

  const uuidToUse = isNotEmpty(uuid) ? uuid : isNotEmpty(uuidFromCookie) ? uuidFromCookie : '';
  store.dispatch(setUuid(uuidToUse));
  store.dispatch(showLoader(true));
  if (!isNotEmpty(interviewConfirmation) || interviewConfirmation.length === 0) {
    getMasterData(false, [INTERVIEW_CONFIRMATION], function (data, error) {
      if (error == null) {
        store.dispatch(receiveInterviewConfirmation(data.interviewConfirmation || []));
        if (!isNotEmpty(interviewDetail) || Object.keys(interviewDetail).length === 0) {
          getAjaxCall(apiUrls.interviewDetail + '/' + uuidToUse, function (data, error) {
            data.currentInterviewDetail.displayName = data.displayName;
            data.currentInterviewDetail.job = data.job;
            if (error == null) {
              store.dispatch(receiveInterviewData(data.currentInterviewDetail));
              store.dispatch(showLoader(false));
            }
            else {
              console.error(error);
              store.dispatch(showLoader(false));
            }
          })
        }
      } else {
        store.dispatch(showLoader(false));
        console.error(error);
      }
    });
  }
};

export const getUniversalChatbotData = () => {
  const { uuid, uChatbotDetails, uChatbotQuestions, uChatbotAnswers } = store.getState();
  let apiUrl = `${apiUrls.fetchUniversalChatbotQuestions}?uuid=${uuid}`;

  store.dispatch(showLoader(true));
  getAjaxCall(apiUrl, function (data, error) {
    if (error === null) {
      store.dispatch(setCandidateInterest(data.candidateInterest));
      store.dispatch(setCandidateInterestDate(data.uchatbotInterestTimestamp));
      store.dispatch(setUChatbotPhase(data.phase));
      store.dispatch(
        setUChatbotDetails({
          ...uChatbotDetails,
          ...{
            candidateFirstName: data.candidateFirstName,
            candidateLastName: data.candidateLastName,
            campaignTitle: data.campaignTitle,
            campaignDescription: data.campaignDescription,
            companyName: data.companyName,
            companyLogo: data.companyLogo,
            companyDescription: data.companyDescription,
            questionToAsk: data.questionToAsk,
          },
        })
      );
      store.dispatch(
        setUChatbotAnswers({
          ...uChatbotAnswers,
          templateAnswers:
            (data.phase === uChatbotPhases.INVITED ||
              data.phase === uChatbotPhases.TEMPLATE) &&
            isNotEmpty(data.answers)
              ? data.answers
              : {},
          defaultAnswers:
            data.phase === uChatbotPhases.DEFAULT && isNotEmpty(data.answers)
              ? data.answers
              : {},
          screeningAnswers:
            data.phase === uChatbotPhases.SCREENING && isNotEmpty(data.answers)
              ? data.answers
              : {},
        })
      );
      store.dispatch(
        setUChatbotQuestions({
          ...uChatbotQuestions,
          ...{
            templateQuestions: data.templateQuestions?.uchatbotTemplateL1List,
            defaultQuestions: data.defaultQuestions,
            screeningQuestions: data.screeningQuestions,
          },
        })
      );
      store.dispatch(showLoader(false));
    }
  });
};

export const setUniversalChatbotUuid = (hash) => {
  let type = "emailLink";
  let uuidHash, params;
  if (hash.includes("?")) {
    let path = hash.split("/").pop();
    [uuidHash, params] = path.split("?");
    type = getUrlLinkType(params);
  } else {
    uuidHash = hash.split("/").pop();
  }
  const uuidFromCookie = getValue("uuid");
  // checks if uuid is valid
  const uuid = extractUuid(uuidHash);

  if (!isNotEmpty(uuid) && !isNotEmpty(uuidFromCookie)) {
    document.location = "#/error";
  }
  const uuidToUse = isNotEmpty(uuid)
    ? uuid
    : isNotEmpty(uuidFromCookie)
      ? uuidFromCookie
      : "";
  setValue("uuid", uuidToUse);
  store.dispatch(setUuid(uuidToUse));
  store.dispatch(setLinkType(type));
};

export const handleResumeUpload = (resume) => {
  const { uuid, isUniversalChatbot } = store.getState();
  const apiUrl = isUniversalChatbot
    ? apiUrls.uChatbotUploadResume
    : apiUrls.uploadResumeURL;
  const route = isUniversalChatbot
    ? urls.UNIVERSAL_CHATBOT_THANKYOU
    : urls.THANKYOU;
  store.dispatch(showLoader(true));
  postAjaxCallHybrid(apiUrl, resume, uuid, (data, error) => {
    if (error === null) {
      store.dispatch(isResumeUploaded(true));
      store.dispatch(showLoader(false));
      document.location = `#${route}`;
    }
  })
};

const extractUuid = (value) => {
  let uuid = value.match(uuidMatcher);
  return isNotEmpty(uuid) ? uuid[0] : null;
};
