import {useState} from 'react';
import './salary.css';
import Range from "../range";
import OKButton from "../../OKButton";
import { func } from 'prop-types';

const Salary = ({handleSubmit}) => {
    const [salary1, setSalary1] = useState(0);
    const [salary2, setSalary2] = useState(0);
    const [salary3, setSalary3] = useState(0);
    const [salary4, setSalary4] = useState(0);


    return (
        <div className={ "" }>
            <div className={"d-flex flex-row justify-content-around m-4"}>
                <div>
                    <div className={" row justify-content-center "}>
                        <Range value={salary1} onChange={setSalary1}/>
                        <Range value={salary2} onChange={setSalary2}/>
                    </div>
                    <div className={"row justify-content-center"}>
                        <p className={'question-breadcrumb'}><i>In lakhs</i></p>
                    </div>
                </div>
                <div>
                    <div className={"row justify-content-center "}>
                        <Range value={salary3} onChange={setSalary3} />
                        <Range value={salary4} onChange={setSalary4} />
                    </div>
                    <div className={"row justify-content-center"}>
                        <p className={'question-breadcrumb'}><i>In thousands</i></p>
                    </div>
                </div>
            </div>
            <br />
            <OKButton onClick={()=>{handleSubmit(`${salary1}${salary2}.${salary3}${salary4}`); setSalary1(0); setSalary2(0); setSalary3(0); setSalary4(0)}}/>
        </div>
    )
};

export default Salary;

Salary.propTypes = {
  handleSubmit: func.isRequired,
};