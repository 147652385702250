import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setAnswer, setQuickAnswer } from "./actions/ChatbotActions";
import hrSection from "./assets/images/hr-section.png";
import hrSectionGray from "./assets/images/hr-section-gray.png";
import jobSection from "./assets/images/job-section.png";
import jobSectionGray from "./assets/images/job-section-gray.png";
import otherSection from "./assets/images/other-section.png";
import otherSectionGray from "./assets/images/other-section-gray.png";
import { isNotEmpty } from "./utils/Helper";
import { apiUrls } from "./services/ApiUrls";
import { postAjaxCall } from "./services/AjaxService";
import { showLoader } from "./actions/LoaderAction";
import { urls } from "./utils/routes";
import { setJobCandidateMappping } from "./actions/DataAction";
import ProgressBar from "./components/common/ProgressBar";
import CategorySelection from "./components/screens/CategorySelection";
import store from "./utils/Store";
import StartQuickScreening from "./components/screens/StartQuickScreening";
import PoweredByLogo from "./components/common/PoweredByLogo";
import EncourageScreen from "./components/screens/EncourageScreen";
import CommonTransition from "./components/common/CommonTransition";
import Question from "./components/QuestionAnswerWrapper/Question";
import Answer from "./components/QuestionAnswerWrapper/Answer";

const questionTypes = {
  HR_SCREENING: {
    label: "HR Screening",
    value: "onlyHrQuestions",
    activeImgSrc: hrSection,
    imgSrc: hrSectionGray,
  },
  TECH_SCREENING: {
    label: "Job Role Section",
    value: "techScreeningQuestions",
    activeImgSrc: jobSection,
    imgSrc: jobSectionGray,
  },
  CUSTOM_SCREENING: {
    label: "Other Section",
    value: "customScreeningQuestions",
    activeImgSrc: otherSection,
    imgSrc: otherSectionGray,
  },
};

const Chatbot = () => {
  const {
    jobDetail,
    jobCandidateMapping,
    hrQuestions,
    answer,
    quickAnswer,
    uuid,
    candidateInterest,
    candidateInterestDate,
    linkType,
  } = useSelector((state) => state);
  const { quickQuestion } = jobDetail;

  const [showChatbot, setShowChatbot] = useState(false);
  const [showIntermediateScreen, setShowIntermediateScreen] = useState(false);
  const [activeQuestionType, setActiveQuestionType] = useState(null);
  const [activeCategory, setActiveCategory] = useState("");
  const [questionIdToRender, setQuestionIdToRender] = useState(null);
  const [questionAnswerJSX, setQuestionAnswerJSX] = useState(null);
  const [categories, setCategories] = useState(null);

  // Screen questions ids for which answer is present
  const qIds = Object.keys(answer).map(Number);

  // Quick screening skill ids for which statement block is already selected
  const quickQIds = Object.keys(quickAnswer).map(Number);

  const questions = {};

  questions.onlyHrQuestions = hrQuestions.filter((question) => {
    if (isNotEmpty(question?.masterScreeningQuestionId)) {
      return question?.masterScreeningQuestionId;
    } else if (question?.companyHrQuestion) {
      return question?.companyHrQuestion;
    }
  });
  questions.techScreeningQuestions = quickQuestion
    ? jobDetail.jobSkillsAttributesList
    : hrQuestions.filter((question) => question.techScreeningQuestionId);
  questions.customScreeningQuestions = hrQuestions.filter(
    (question) => question.userScreeningQuestionId
  );

  useEffect(() => {
    if (
      !quickQuestion &&
      isNotEmpty(activeQuestionType) &&
      activeQuestionType.value === questionTypes.TECH_SCREENING.value
    ) {
      let categoriesFromTechQuestion = {};

      questions.techScreeningQuestions.forEach(
        ({ techScreeningQuestionId: question }) => {
          if (
            !isNotEmpty(categoriesFromTechQuestion[question.questionCategory])
          ) {
            categoriesFromTechQuestion[question.questionCategory] = {};
            categoriesFromTechQuestion[
              question.questionCategory
            ].questionCount = 0;
            categoriesFromTechQuestion[
              question.questionCategory
            ].isCompleted = false;
          }

          categoriesFromTechQuestion[
            question.questionCategory
          ].questionCount += 1;
          categoriesFromTechQuestion[question.questionCategory].isCompleted =
            isCategoryComplete(question.questionCategory);
        }
      );

      setCategories(categoriesFromTechQuestion);
    }
  }, [activeQuestionType, answer]);

  // Below useEffect redirects to correct route based on chatbot status and candidate interest.
  useEffect(() => {
    if (!candidateInterest && !isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.DECLINE_THANKYOU}`;
    } else if (jobCandidateMapping.chatbotStatus === "Complete") {
      document.location = `#${urls.UPLOAD_RESUME}`;
    }
  }, [candidateInterest, candidateInterestDate, jobCandidateMapping]);

  useEffect(() => {
    if (questionIdToRender !== null && activeQuestionType !== null)
      getQuestionAnswerJSX();
  }, [questionIdToRender]);

  //Below useEffect sets chat complete status in redux store if all questions are answered for current active question type.
  useEffect(() => {
    const hrQIds = hrQuestions.map((question) => question.id);

    const completed = hrQIds.every((id) => qIds.includes(id));

    if (
      (Object.keys(qIds).length + Object.keys(quickQIds).length) % 7 === 0 &&
      Object.keys(qIds).length + Object.keys(quickQIds).length > 0 &&
      showChatbot
    ) {
      setShowIntermediateScreen(true);
    } else {
      setShowIntermediateScreen(false);
    }

    if (!quickQuestion && completed) {
      store.dispatch(
        setJobCandidateMappping({
          ...jobCandidateMapping,
          ...{ chatbotStatus: "Complete" },
        })
      );
    } else if (!quickQuestion && !completed) {
      if (showChatbot) setQuestionId();
    } else if (quickQuestion && !completed) {
      if (showChatbot) setQuestionId(); //
    } else if (quickQuestion && completed) {
      const skillsIds = jobDetail.jobSkillsAttributesList.map(
        (skill) => skill.id
      );

      const quickScreeningCompleted = skillsIds.every((id) =>
        quickQIds.includes(id)
      );

      if (quickScreeningCompleted && completed) {
        store.dispatch(
          setJobCandidateMappping({
            ...jobCandidateMapping,
            ...{ chatbotStatus: "Complete" },
          })
        );
      } else {
        if (showChatbot) setQuestionId();
      }
    }
  }, [answer, quickAnswer, showChatbot]);

  const getQuestionAnswerJSX = () => {
    const activeQuestions = questions[activeQuestionType.value];
    if (showChatbot) {
      const question =
        activeQuestions[
          activeQuestions.findIndex(
            (question) => question.id === questionIdToRender
          )
        ];
      const questionParam =
        question?.masterScreeningQuestionId ||
        question?.techScreeningQuestionId ||
        question?.customScreeningQuestionId ||
        question?.userScreeningQuestionId ||
        question?.companyHrQuestion ||
        question?.companyScreeningQuestionId;
      if (!quickQuestion && isNotEmpty(questionParam)) {
        setQuestionAnswerJSX([
          <ProgressBar
            key={`${activeQuestionType.value}_${question?.id}_progressbar`}
            complete={qIds.length}
            total={hrQuestions.length}
          />,
          <CommonTransition
            key={`${activeQuestionType.value}_${questionIdToRender}_qaWrapper`}
          >
            <Question
              questionId={question?.id}
              questionText={
                questionParam?.question ||
                questionParam?.techQuestion ||
                undefined
              }
              skill={
                questionParam?.skillId?.skillName ||
                questionParam?.attribute?.jobAttribute
              }
              smallText={questionParam?.smallText || ""}
            />

            <Answer
              handleSubmit={handleSubmit}
              questionId={question?.id}
              question={questionParam}
            />
          </CommonTransition>,
        ]);
      } else if (quickQuestion) {
        setQuestionAnswerJSX([
          <ProgressBar
            key={`${activeQuestionType.value}_${question?.id}_progressbar`}
            complete={qIds.length + quickQIds.length}
            total={
              hrQuestions.length + jobDetail?.jobSkillsAttributesList?.length
            }
          />,
          <CommonTransition
            key={`${activeQuestionType.value}_${questionIdToRender}_qaWrapper`}
          >
            <Question
              questionId={question?.id}
              questionText={
                questionParam?.question ||
                questionParam?.techQuestion ||
                undefined
              }
              skill={
                question?.skillId?.skillName ||
                question?.attribute?.jobAttribute
              }
              smallText={questionParam?.smallText || ""}
            />

            <Answer
              handleSubmit={handleSubmit}
              questionId={question?.id}
              question={questionParam || question}
            />
          </CommonTransition>,
        ]);
      }
    }
  };

  const candidateName = isNotEmpty(jobCandidateMapping)
    ? jobCandidateMapping.displayName
    : "";

  function isCompleted(questionType) {
    return questions[questionType]
      .map((obj) => obj.id)
      .every((id) => qIds.includes(id) || quickQIds.includes(id));
  }

  function isCategoryComplete(questionCategory) {
    return questions.techScreeningQuestions
      .filter(
        (obj) =>
          obj.techScreeningQuestionId.questionCategory === questionCategory
      )
      .every((obj) => qIds.includes(obj.id));
  }

  function handlecategorySelect(category) {
    setActiveCategory(category);
    setShowChatbot(true);
  }

  function handleSubmit(qAnswer, isOtherLocation, questionId) {
    let apiUrl = `${apiUrls.sendChatbotAnswers}?uuid=${uuid}`;
    let reqData;

    isOtherLocation
      ? (reqData = {
          deepScreeningQuestionResponseMap: {
            [questionId]: qAnswer[questionId],
          },
          quickScreeningQuestionResponseMap: {},
          otherLocation: qAnswer[questionId][0],
          type: linkType,
        })
      : quickQuestion &&
        activeQuestionType.value === questionTypes.TECH_SCREENING.value
      ? (reqData = {
          deepScreeningQuestionResponseMap: {},
          quickScreeningQuestionResponseMap: qAnswer,
          otherLocation: "",
          type: linkType,
        })
      : (reqData = {
          deepScreeningQuestionResponseMap: qAnswer,
          quickScreeningQuestionResponseMap: {},
          otherLocation: "",
          type: linkType,
        });

    store.dispatch(showLoader(true));

    postAjaxCall(apiUrl, reqData, function (data, error) {
      if (error === null) {
        if (
          quickQuestion &&
          activeQuestionType.value === questionTypes.TECH_SCREENING.value
        ) {
          store.dispatch(setQuickAnswer({ ...qAnswer, ...quickAnswer }));
        } else {
          store.dispatch(setAnswer({ ...qAnswer, ...answer }));
        }
        store.dispatch(showLoader(false));
      }
    });
  }

  function setQuestionId() {
    const activeQuestions = questions[activeQuestionType.value];
    let questionId = null;
    if (showChatbot) {
      if (
        (!quickQuestion &&
          activeQuestions.every((question) => qIds.includes(question.id))) ||
        (quickQuestion &&
          activeQuestions.every(
            (question) =>
              qIds.includes(question.id) || quickQIds.includes(question.id)
          ))
      ) {
        setShowChatbot(false);
        setActiveQuestionType(null);
      } else {
        if (!quickQuestion) {
          if (activeQuestionType.value === questionTypes.TECH_SCREENING.value) {
            questionId =
              activeQuestions[
                activeQuestions.findIndex(
                  (question) =>
                    !qIds.includes(question.id) &&
                    question.techScreeningQuestionId.questionCategory ===
                      activeCategory
                )
              ]?.id;
          } else {
            questionId =
              activeQuestions[
                activeQuestions.findIndex(
                  (question) => !qIds.includes(question.id)
                )
              ]?.id;
          }
        } else {
          if (activeQuestionType.value === questionTypes.TECH_SCREENING.value) {
            questionId =
              activeQuestions[
                activeQuestions.findIndex(
                  (question) => !quickQIds.includes(question.id)
                )
              ]?.id;
          } else {
            questionId =
              activeQuestions[
                activeQuestions.findIndex(
                  (question) => !qIds.includes(question.id)
                )
              ]?.id;
          }
        }
      }
      if (undefined === questionId) {
        setQuestionIdToRender(null);
        setShowChatbot(false);
        setActiveCategory("");
      } else {
        setQuestionIdToRender(questionId);
      }
    }
  }

  return (
    <div>
      <div className={"chatbot-container"}>
        {showIntermediateScreen && (
          <div
            style={{
              height: "100vh",
              position: "fixed",
              background: "white",
              width: "inherit",
              top: "10px",
              zIndex: "4",
              marginTop: "-11px",
              padding: "18px 18px 18px 0px",
              marginLeft: "-8px",
            }}
          >
            <ProgressBar
              complete={qIds.length + quickQIds.length}
              total={
                quickQuestion
                  ? hrQuestions.length +
                    jobDetail?.jobSkillsAttributesList?.length
                  : hrQuestions.length
              }
            />
            <EncourageScreen
              setShowIntermediateScreen={setShowIntermediateScreen}
            />
          </div>
        )}

        {showChatbot && questionAnswerJSX}
        {!showChatbot &&
          !quickQuestion &&
          activeQuestionType !== null &&
          activeQuestionType.value === questionTypes.TECH_SCREENING.value &&
          isNotEmpty(categories) &&
          !isNotEmpty(activeCategory) && (
            <>
              <ProgressBar
                complete={qIds.length + quickQIds.length}
                total={hrQuestions.length}
              />
              <CategorySelection
                categories={categories}
                onCategorySelect={handlecategorySelect}
              />
            </>
          )}
        {!showChatbot &&
          quickQuestion &&
          activeQuestionType !== null &&
          activeQuestionType.value === questionTypes.TECH_SCREENING.value && (
            <div>
              <StartQuickScreening
                setShowChatbot={setShowChatbot}
                skillsAndAttributes={
                  jobDetail?.jobSkillsAttributesList?.map(
                    (skill) =>
                      skill?.skillId?.skillName ||
                      skill?.attribute?.jobAttribute
                  ) || []
                }
              />
            </div>
          )}
        {!showChatbot && activeQuestionType === null && (
          <div className="Hr-selection-screen">
            <h4>
              <span className="c-name">{candidateName},</span> these are the
              sections you need to go through.
            </h4>
            <p>
              Start with the HR Section and answer those questions first. Once
              you are done, you will come back to this page again. Then select
              the Job Role Section and complete those questions.
            </p>

            {Object.values(questionTypes).map((questionType, index) => {
              const completed = isCompleted(questionType.value);
              let previousComplete = false;
              if (index > 0) {
                previousComplete = isCompleted(
                  Object.values(questionTypes)[index - 1]?.value
                );
              }
              return (
                questions[questionType.value].length > 0 && (
                  <div
                    className={
                      (index === 0 && completed) ||
                      (index > 0 && (completed || !previousComplete))
                        ? "section-outer inactive"
                        : "section-outer"
                    }
                    onClick={() => {
                      if (
                        (index === 0 && !completed) ||
                        (index > 0 && previousComplete && !completed)
                      ) {
                        setActiveQuestionType(questionType);
                        if (
                          questionType.value !==
                          questionTypes.TECH_SCREENING.value
                        )
                          setShowChatbot(true);
                      } else if (index > 0 && !previousComplete) {
                        alert(
                          `complete the ${
                            Object.values(questionTypes)[index - 1]?.label
                          } section first`
                        );
                      } else {
                        alert("This section is already completed");
                      }
                    }}
                    key={questionType.value + "_card"}
                  >
                    <span className="section-heading">
                      <h4>{questionType.label} </h4>
                      <span className="q-text">
                        ({questions[questionType.value].length} Questions)
                      </span>
                    </span>
                    <span className="section-img">
                      <img
                        src={
                          completed
                            ? questionType.imgSrc
                            : questionType.activeImgSrc
                        }
                        alt={`${questionType.label}_image`}
                        style={{ marginLeft: "6px" }}
                      />
                    </span>
                  </div>
                )
              );
            })}
          </div>
        )}
        <PoweredByLogo />
      </div>
    </div>
  );
};
export default Chatbot;
