export const SET_UUID="SET_UUID";
export const SET_JOB_DETAIL = "SET_JOB_DETAIL";
export const SET_JOB_CANDIDATE_MAPPING = "SET_JOB_CANDIDATE_MAPPING";
export const SET_CREATED_BY = "SET_CREATED_BY";
export const SET_JCM_COMMUNICATION_DETAILS = "SET_JCM_COMMUNICATION_DETAILS";
export const SET_HR_QUESTIONS = "SET_HR_QUESTIONS";
export const SET_CHATBOT_CONTENT = "SET_CHATBOT_CONTENT";
export const RECEIVE_CHATBOT_DATA = "RECEIVE_CHATBOT_DATA";
export const RECEIVE_INTERVIEW_DATA = "RECEIVE_INTERVIEW_DATA";
export const RECEIVE_INTERVIEW_CONFIRMATION = "RECEIVE_INTERVIEW_CONFIRMATION";
export const SET_CHATBOT_ERROR_MESSAGE = "SET_CHATBOT_ERROR_MESSAGE";

export const setUuid = (uuid) => {
    return {
        type: SET_UUID,
        uuid
    }
};

export const setJobDetail = (jobDetail) => {
    return {
        type: SET_JOB_DETAIL,
        jobDetail
    }
};

export const setJobCandidateMappping = (jobCandidateMapping) => {
    return{
        type: SET_JOB_CANDIDATE_MAPPING,
        jobCandidateMapping
    }
};

export const setCreatedBy = (createdBy) => {
    return {
        type: SET_CREATED_BY,
        createdBy
    }
};

export const setJcmCommunicationDetails = (jcmCommunicationDetails) => {
    return {
        type: SET_JCM_COMMUNICATION_DETAILS,
        jcmCommunicationDetails
    }
};

export const setHrQuestions = (hrQuestions) => {
    return {
        type: SET_HR_QUESTIONS,
        hrQuestions
    }
};

export const setChatbotContent = (chatbotContent) => {
    return {
        type: SET_CHATBOT_CONTENT,
        chatbotContent
    }
};

export const receiveInterviewData = (interviewData) => {
    return {
        type: RECEIVE_INTERVIEW_DATA,
        interviewData
    }
};

export const receiveInterviewConfirmation = (interviewConfirmationOptions) => {
    return {
        type: RECEIVE_INTERVIEW_CONFIRMATION,
        interviewConfirmationOptions
    }
};

export const setChatbotErrorMessage = (chatbotErrorMessage) => {
    return {
        type: SET_CHATBOT_ERROR_MESSAGE,
        chatbotErrorMessage
    }
};
