import React from 'react';
import { renderAnswerTypes } from "../../utils/Constant";
import CalendarInput from "../answerTypes/calendar";
import CheckBoxWrapper from "../answerTypes/Checkbox/CheckBoxWrapper";
import TextInput from "../answerTypes/TextInput";
import RadioButtonWrapper from "../answerTypes/RadioButton/RadioButtonWrapper";
import Salary from "../answerTypes/salary";
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, isNotEmpty } from "../../utils/Helper";
import YesNoRadioButton from "../answerTypes/yesNoRadioButton";
import Dropdown from '../answerTypes/Dropdown';

const Answer = ({ handleSubmit, question, questionId }) => {
    const { jobDetail } = useSelector(state => state);

    const quickQuestion = jobDetail?.quickQuestion;
    const statementBlockOptions = jobDetail?.statementBlock?.options || [];

    const isYesNo = (options) => {
        return options && options.length === 2 && options.every(option => ["yes", "no"].includes(option.toLowerCase()))
    };

    const submitAnswer = (answer, isOtherLocation) => {
        let value;

        if (!quickQuestion || isNotEmpty(question.questionType)) {
            value = {
                [questionId]: typeof answer === "object" ? answer : [answer],
            };
        } else {
            value = {
                [questionId]: typeof answer === "object" ? answer[0] : answer,
            };
        }
        if (isOtherLocation) {
            handleSubmit(value, isOtherLocation, questionId);
        } else {
            handleSubmit(value);
        }
    };

    let answerJSX = <div />;

    if (!quickQuestion || isNotEmpty(question.questionType)) {
        switch (question?.questionType.value) {
            case renderAnswerTypes.CALENDAR:
                answerJSX = <CalendarInput handleSubmit={submitAnswer} questionType={question.questionType.value} />;
                break;
            case renderAnswerTypes.CHECK_BOX:
                answerJSX = <CheckBoxWrapper handleSubmit={submitAnswer} options={question.options || []} />;
                break;
            case renderAnswerTypes.FUTURE_CALENDAR:
                answerJSX = <CalendarInput handleSubmit={submitAnswer} questionType={question.questionType.value} />;
                break;
            case renderAnswerTypes.PAST_CALENDAR:
                answerJSX = <CalendarInput handleSubmit={submitAnswer} questionType={question.questionType.value} />;
                break;
            case renderAnswerTypes.INPUT_BOX:
                answerJSX = <TextInput handleSubmit={submitAnswer} />;
                break;
            case renderAnswerTypes.LOCATION:
                answerJSX = <RadioButtonWrapper options={question.options || []} handleSubmit={submitAnswer} multiLevelOptions={isNotEmpty(question.multiLevelOptions) ? JSON.parse(question.multiLevelOptions) : {}} />;
                break;
            case renderAnswerTypes.RADIO_BUTTON:
                if (isYesNo(question.options)) {
                    answerJSX = <YesNoRadioButton handleSubmit={submitAnswer} />
                } else {
                    answerJSX = <RadioButtonWrapper options={question.options || []} handleSubmit={submitAnswer} multiLevelOptions={isNotEmpty(question.multiLevelOptions) ? JSON.parse(question.multiLevelOptions) : {}} />;
                }
                break;
            case renderAnswerTypes.SLIDER:
                if (jobDetail.currency === "INR") {
                    answerJSX = <Salary handleSubmit={submitAnswer} />;
                } else {
                    answerJSX = <TextInput questionType={question?.questionType.value} handleSubmit={submitAnswer} />;
                }
                break;
            case renderAnswerTypes.FILTER_DROPDOWN:
                answerJSX = (
                  <Dropdown
                    masterDataKey={
                      question?.options ? question?.options[0] : ""
                    }
                    handleSubmit={submitAnswer}
                    placeHolder={`Select ${capitalizeFirstLetter(
                      question.options[0]
                    )}...`}
                    dropdownClassName={
                      question?.options
                        ? `${question?.options[0]} dropdown`
                        : "dropdown"
                    }
                  />
                );
                break;
            default:
                console.error("answer type not matched");
                break;
        }
    } else {
        answerJSX = <RadioButtonWrapper handleSubmit={submitAnswer} options={statementBlockOptions} />
    }

    return (
        <div>
            {
                answerJSX
            }
        </div>
    )
};

export default Answer;