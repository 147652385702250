import {SET_UUID} from '../../actions/DataAction'

const uuid = (uuid=null, action) => {
    switch (action.type) {
        case SET_UUID:
            return action.uuid;
        default:
            return uuid;
    }
};

export default uuid;