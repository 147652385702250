import PoweredByLogo from "./common/PoweredByLogo";
import ButtonOrange from "./common/ButtonOrange";
import { func, string } from 'prop-types';


const StickyFooter = ({onClick, btnText})=>(
    <div className="sticky-footer">
        <ButtonOrange onClick={onClick} btnText={btnText}/>
        <PoweredByLogo/>

    </div>
);

export default StickyFooter;

StickyFooter.propTypes = {
  onClick: func.isRequired,
  btnText: string.isRequired,
};