import React, {useEffect} from 'react';
import {isNotEmpty} from "../../utils/Helper";
import {getValue} from "../../services/CookieService";
import {uuidMatcher} from "../../utils/Constant";
import ThankyouSection from "./ThankyouSection";
import {useSelector} from "react-redux";
import {getInterviewData} from "../../services/DataService";

const Reschedule = ()=> {
    const interviewDetail = useSelector(state=>state.interviewDetail);

    useEffect(()=>{
        if(isNotEmpty(interviewDetail.candidateConfirmationTime)){
            document.location = '#/interview/alreadyconfirmed'
        }
    }, []);

    if (!isNotEmpty(interviewDetail)) {
        if (!isNotEmpty(getValue('interviewUuid'))&& uuidMatcher.test(getValue('interviewUuid'))) {
            getInterviewData(getValue('interviewUuid'));
        }
    }
    return (
        isNotEmpty(interviewDetail) &&
        <div className="chatbot-container">
            <p className="mb-4 mt-3"style={{fontSize:"21px",color:"#000",lineHeight:"1.2em"}}>
                Did something urgent come up,
                <br/> <span style={{textTransform:"capitalize"}}><b>{interviewDetail.displayName}?</b></span>
            </p>

            <p>Sorry to hear that you can’t make it for the interview this time.
                Give us some time to check the possibility of rescheduling your interview on another date.
                We’ll be in touch with you if we can make that happen.</p>
            <div className="">
                <ThankyouSection />
            </div>
        </div>
    )
}

export default Reschedule