import { useSelector } from "react-redux";
import { StringToHtml } from "../../utils/Helper";
import PoweredByLogo from "../common/PoweredByLogo";
import OKButton from "../OKButton";

const UChatbotStartHrSection = ({ handleClick }) => {
  const { uChatbotDetails } = useSelector((state) => state);
  const { candidateFirstName, questionToAsk } = uChatbotDetails;
  return (
    <div
      style={{
        height: "90vh",
        position: "fixed",
        width: "inherit",
        zIndex: 4,
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 18px 18px 0px",
      }}
    >
      <h4 className={"q-text"}>
        {StringToHtml(
          questionToAsk.templateCompletion.replace(
            "$candidateName",
            candidateFirstName
          ),
          "p"
        )}
      </h4>
      <br />
      <OKButton
        style={{ paddingLeft: "10px" }}
        btnText={"Let’s begin!"}
        disabled={false}
        onClick={() => handleClick(true)}
      />
      <PoweredByLogo />
    </div>
  );
};

export default UChatbotStartHrSection;
