/**
 * @author : sameer
 * Date : 5/8/19
 * Time : 10:30 AM
 * Project Name : litmusblox-frontend
 */

export function setValueInLocalStorage(key, value){
    return localStorage.setItem(key,JSON.stringify(value));
}
export function getValueFromLocalStorage(key){
    return JSON.parse(localStorage.getItem(key));
}
export function removeValueFromLocalStorage(key){
    return localStorage.removeItem(key);
}