import React from 'react'
import './index.css';
import greyCheck from '../../../assets/images/gray-check.png'

const CategoryCard = ({category, questionCount, complete, onClick}) => {
    return (
        <div className="skillQuestion__container" onClick={onClick}>
            <div className={complete ? "skill__container-complete": "skill__container"}>
                <h4 className="skill__text">{category}</h4>
            </div>
            <div className="question__container">
                {
                    complete ?
                        <div className='text-complete-div'>
                            <img className="checkmark-complete" src={greyCheck} alt={"true"}/>
                            <h4 className="question__text-complete">Completed</h4>
                        </div>

                        : <h4 className="question__text">{questionCount} Questions</h4>
                }
            </div>

        </div>
    )
};

export default CategoryCard;
