import {IS_RESUME_UPLOADED} from "../actions/ResumeAction";

const isResumeUploaded = (state=false, action) => {
    switch(action.type){
        case IS_RESUME_UPLOADED:
            return action.status;
        default:
            return state
    }
};

export default isResumeUploaded;