import { useEffect, useState } from "react";
import { array, object, bool, func } from "prop-types";
import Radio from "./index";
import OKButton from "../../OKButton";
import { isNotEmpty } from "../../../utils/Helper";
import OtherOptionTextInput from "../OtherOptionTextInput/OtherOptionTextInput";
import { useSelector } from "react-redux";

const RadioButtonWrapper = ({
  options,
  multiLevelOptions,
  handleSubmit,
  isScreeningQuestion,
}) => {
  const { uChatbotAnswers, isUniversalChatbot } = useSelector((state) => state);
  const [radio, setRadio] = useState([]);
  const [textInputValue, setTextInputValue] = useState("");
  const [disabledFlag, setDisabledFlag] = useState(true);
  const [validationText, setValidationText] = useState("");

  useEffect(() => {
    isDisabled();
  }, [radio, textInputValue]);

  const isDisabled = () => {
    let flag = true;
    if (radio.length > 0) {
      if (
        !isNotEmpty(isScreeningQuestion) &&
        isUniversalChatbot
      ) {
        flag =
          radio[0] === "Other" && textInputValue.replace(/ /g, "").length === 0
            ? true
            : false;
        // setValidationText("");
        // UChatbot: if current qs text input is same as any previous template answer
        // for (const answerObj of uChatbotAnswers.templateAnswers) {
        //   if (answerObj[textInputValue]) {
        //     flag = true;
        //     setValidationText("Validation Failed!");
        //   }
        // }
      } else {
        flag = false;
      }
      setDisabledFlag(flag);
    }
  };

  const handleOkButton = () => {
    // should be uchatbot qs and should not be a screening qs
    if (
      !isNotEmpty(isScreeningQuestion) &&
      isUniversalChatbot &&
      radio[0] === "Other"
    ) {
      handleSubmit(textInputValue);
    } else {
      handleSubmit(radio);
    }
  };

  return (
    <>
      <div>
        {/* Multilevel Options Logic */}
        {isNotEmpty(multiLevelOptions) &&
          Object.keys(multiLevelOptions).length > 0 &&
          Object.keys(multiLevelOptions).map((key, index) => (
            <Radio
              key={`${key}_${index}`}
              checked={radio.length > 0 && radio[0] === key}
              label={key}
              value={key}
              handleChange={() => setRadio([key])}
            />
          ))}
        {radio.length > 0 &&
          isNotEmpty(radio[0]) &&
          isNotEmpty(multiLevelOptions) &&
          Object.keys(multiLevelOptions).length > 0 &&
          Object.keys(multiLevelOptions).includes(radio[0]) &&
          multiLevelOptions[radio[0]].map((option, index) => (
            <Radio
              key={`${option}_${index}`}
              checked={radio.length > 1 && radio[1] === option}
              label={option}
              value={option}
              handleChange={() => setRadio([radio[0], option])}
            />
          ))}

        {/* Single Option Logic */}
        {options.map((option, index) => (
          <Radio
            key={`${option}_${index}`}
            checked={radio.length > 0 && radio[0] === option}
            label={option}
            value={option}
            handleChange={() => {
              setRadio([option]);
            }}
          />
        ))}
      </div>
      <br />
      {/* should be uchatbot qs and should not be a screening qs */}
      {!isNotEmpty(isScreeningQuestion) &&
        isUniversalChatbot &&
        radio[0] === "Other" && (
          <>
            <OtherOptionTextInput
              inputValue={textInputValue}
              handleChange={(inputValue) => setTextInputValue(inputValue)}
            />
            <span>{validationText}</span>
          </>
        )}
      <br />

      <OKButton
        disabled={disabledFlag}
        onClick={() => {
          handleOkButton();
          setRadio([]);
          setTextInputValue("");
          setDisabledFlag(true);
        }}
      />
    </>
  );
};

export default RadioButtonWrapper;

RadioButtonWrapper.propTypes = {
  options: array.isRequired,
  multiLevelOptions: object,
  handleSubmit: func.isRequired,
  isScreeningQuestion: bool,
};
