export const levels = { LEVEL1: "level1", LEVEL2: "level2" };

export const UChatbotQs = {
  EXPERIENCE_RANGE_QS: "How many years of experience do you have in <b>$label</b>",
  TOP_SKILLS_TAG: "TOP_5_SKILLS",
  OTHER_SKILLS_TAG: "OTHER_SKILLS",
};

export const uChatbotPhases = {
  INVITED: "invited",
  TEMPLATE: "template",
  DEFAULT: "default_question",
  SCREENING: "screening",
  COMPLETE: "complete",
};
