import Checkbox from ".";
import React, { useEffect, useState } from "react";
import { bool, number, func, string, arrayOf } from "prop-types";
import OKButton from "../../OKButton";
import OtherOptionTextInput from "../OtherOptionTextInput/OtherOptionTextInput";
import { isNotEmpty } from "../../../utils/Helper";
import { useSelector } from "react-redux";

const CheckBoxWrapper = ({
  options,
  handleSubmit,
  isScreeningQuestion,
  anyN,
}) => {
  const { uChatbotAnswers, isUniversalChatbot } = useSelector((state) => state);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [joinedOptions, setJoinedOptions] = useState("");
  const [textInputValue, setTextInputValue] = useState("");
  const [disabledFlag, setDisabledFlag] = useState(true);
  const [validationText, setValidationText] = useState("");

  useEffect(() => {
    isDisabled();
  }, [selectedOptions, textInputValue]);

  const ARRAY_JOINER = "%$";
  useEffect(() => {
    setJoinedOptions(selectedOptions.join(ARRAY_JOINER));
  }, [selectedOptions]);

  const handleChange = (option) => {
    const selectedOptionIndex = selectedOptions.indexOf(option);
    // If already selected, remove from selectedOptions
    if (selectedOptionIndex !== -1) {
      setSelectedOptions(
        selectedOptions.filter((selectedOption) => selectedOption !== option)
      );
    } else if (isUniversalChatbot && !isScreeningQuestion) {
      if (selectedOptions.length < anyN) {
        // Add a new selected option
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const isDisabled = () => {
    let tempDisabledFlag;
    if (isNotEmpty(selectedOptions) && selectedOptions.length !== 0) {
      // If its a regular chatbot qs or uchatbot screening qs
      if (
        !isNotEmpty(isScreeningQuestion) &&
        isUniversalChatbot
      ) {
        tempDisabledFlag =
          anyN && selectedOptions.length <= anyN
            ? selectedOptions.includes("Other") &&
              textInputValue.replace(/ /g, "").length === 0
              ? true
              : false
            : true;
        // setValidationText("");
        // UChatbot: if current qs text input is same as any previous template answer
        // for (const answerObj of uChatbotAnswers.templateAnswers) {
        //   if (answerObj[textInputValue]) {
        //     tempDisabledFlag = true;
        //     setValidationText("Answer cannot be same as previous one");
        //   }
        // }
      } else {
        tempDisabledFlag = false;
      }
    } else {
      tempDisabledFlag = true;
    }
    setDisabledFlag(tempDisabledFlag);
  };

  const handleOkButton = () => {
    if (
      !isNotEmpty(isScreeningQuestion) &&
      isUniversalChatbot &&
      joinedOptions.includes("Other")
    ) {
      let tempJoinedOptions = joinedOptions.replace("Other", textInputValue);
      setJoinedOptions(tempJoinedOptions);
      handleSubmit(tempJoinedOptions);
    } else {
      handleSubmit(joinedOptions);
    }
  };

  return (
    <>
      <div>
        {options.map((option, index) => (
          <div
            key={`checkbox_option_${index}`}
            style={{ marginBottom: "16px" }}
          >
            <Checkbox
              checked={selectedOptions.indexOf(option) !== -1}
              onChange={handleChange}
              label={option}
            />
          </div>
        ))}
      </div>
      <br />
      {!isNotEmpty(isScreeningQuestion) &&
        isUniversalChatbot &&
        selectedOptions &&
        selectedOptions.includes("Other") && (
          <>
            <OtherOptionTextInput
              inputValue={textInputValue}
              handleChange={(inputValue) => setTextInputValue(inputValue)}
              validationText={validationText}
            />
          </>
        )}
      <br />
      <OKButton
        onClick={() => {
          handleOkButton();
          setSelectedOptions([]);
          setTextInputValue("");
          setDisabledFlag(true);
        }}
        disabled={disabledFlag}
      />
    </>
  );
};

export default CheckBoxWrapper;

CheckBoxWrapper.propTypes = {
  options: arrayOf(string).isRequired,
  handleSubmit: func.isRequired,
  isScreeningQuestion: bool,
  anyN: number,
};
