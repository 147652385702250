import LinkedInIcon from "../../assets/images/linkedin.png";
import FacebookIcon from "../../assets/images/fb-icon.png";
import PoweredByLogo from "../common/PoweredByLogo";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { isNotEmpty } from "../../utils/Helper";
import { urls } from "../../utils/routes";

const UniversalChatbotDeclineThanks = () => {
  const {
    uChatbotDetails,
    candidateInterest,
    uChatbotPhase,
    candidateInterestDate,
  } = useSelector((state) => state);
  const { companyName, candidateFirstName, candidateLastName } =
    uChatbotDetails;

  useEffect(() => {
    if (candidateInterest && uChatbotPhase !== "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    } else if (candidateInterest && uChatbotPhase === "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    }
  }, [candidateInterest, candidateInterestDate, uChatbotPhase]);

  return (
    <div className="chatbot-container Hr-selection-screen bottom-container">
      <h4>
        <span className="c-name">
          {candidateFirstName} {candidateLastName},
        </span>{" "}
        got it and thanks nevertheless!.
      </h4>
      <p>
        An increasing number of companies are using {companyName} for making the
        candidate application process smoother.
      </p>
      <p>
        You may be invited by other companies and asked to complete a quick
        profiling via a chatbot like this. Be on the lookout.
      </p>
      <p>
        Best wishes for your career and I hope our paths cross again in the
        future!
      </p>
      <div className="social-links">
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/litmusblox?originalSubdomain=in",
              "_blank"
            )
          }
        >
          <img src={LinkedInIcon} alt="LinkedIn" />
        </span>
        <span
          onClick={() =>
            window.open("https://www.facebook.com/litmusblox/", "_blank")
          }
        >
          <img src={FacebookIcon} alt="Facebook" />
        </span>
        <PoweredByLogo />
      </div>
    </div>
  );
};

export default UniversalChatbotDeclineThanks;
