import React, {useEffect} from 'react';
import ButtonOrange from "./components/common/ButtonOrange";
import PoweredByLogo from "./components/common/PoweredByLogo";
import {useSelector} from "react-redux";
import {urls} from "./utils/routes";
import {isNotEmpty} from "./utils/Helper";

const DeclineScreen = ()=> {
    const {jobCandidateMapping, candidateInterest, candidateInterestDate} = useSelector(state=>state);

    useEffect(()=>{
        if(candidateInterest){
            document.location = `#${urls.CHATBOT}`
        }
        else if(!candidateInterest && isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.DECLINE_THANKYOU}`
        }
        else if(candidateInterest && jobCandidateMapping.chatbotStatus === "Complete"){
            document.location = `#${urls.UPLOAD_RESUME}`
        }
    }, [candidateInterest, candidateInterestDate]);

    const candidateName = isNotEmpty(jobCandidateMapping)?jobCandidateMapping.displayName:"";
    return(
        <div className="chatbot-container Hr-selection-screen bottom-container">
            <h4>
                <span className="c-name">{candidateName},</span> I understand?
            </h4>
            <p>
                Sometimes an opportunity may not be the right fit for your career goals. May I know the reason for decline?
            </p>
            <ButtonOrange btnText={"Specific reasons for decline?"} onClick={()=>document.location = `#${urls.DECLINE_REASON}`}/>
            <PoweredByLogo/>
        </div>
    )
};
export default DeclineScreen;