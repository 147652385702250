/**
 * @author : sameer
 * Date : 29/11/19
 * Time : 11:01 AM
 * Project Name : litmusblox-chatbot
 */

import React from 'react';

const AnimatedLoader = (props) => (
    <div className={"loader-background"}>
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="cube" />
            </div>
            <div className="loader-wrapper">
                <div className="cube" />
            </div>
            <div className="loader-wrapper">
                <div className="cube" />
            </div>
        </div>
    </div>
);
export default AnimatedLoader;