import React, { useEffect, useState } from "react";
import { isNotEmpty } from "../../utils/Helper";
import { getValue } from "../../services/CookieService";
import { putAjaxCallWithData } from "../../services/AjaxService";
import { apiUrls } from "../../services/ApiUrls";
import { uuidMatcher } from "../../utils/Constant";
import { getInterviewData } from "../../services/DataService";
import { useSelector } from "react-redux";
import AnimatedLoader from "../common/AnimatedLoader";

const options = {
  yes: "Yes, I will attend the interview",
  no: "No, I am not able to attend the interview",
  reschedule: "I wish to reschedule the interview",
};

const Consent = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  const { interviewDetail } = useSelector((state) => state);
  useEffect(() => {
    const action = new URLSearchParams(props.location.search).get("action");
    if (Object.keys(interviewDetail).length > 0) {
      if (
        isNotEmpty(interviewDetail) &&
        isNotEmpty(interviewDetail?.candidateConfirmationTime)
      ) {
        setSelectedOption(interviewDetail.candidateConfirmationValue?.value);
        redirect();
      } else if (action) {
        if (action in options) {
          const option = options[action];
          setSelectedOption(option);
          submitConsent(option);
        }
      }
    } else {
      if (
        isNotEmpty(getValue("interviewUuid")) &&
        uuidMatcher.test(getValue("interviewUuid"))
      ) {
        getInterviewData(getValue("interviewUuid"));
      } else {
        console.error("interview id is missing");
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(interviewDetail).length > 0) {
      if (
        isNotEmpty(interviewDetail) &&
        isNotEmpty(interviewDetail.candidateConfirmationTime)
      ) {
        redirect();
      }
    }
  }, [selectedOption]);

  const submitConsent = (option) => {
    let requestData = {
      interviewReferenceId: getValue("interviewUuid"),
      confirmationText: option,
    };

    setLoading(true);
    putAjaxCallWithData(
      apiUrls.interviewConfirmationURL,
      requestData,
      function (data, error) {
        setLoading(false);
        if (error == null) {
          redirect(option);
        }
      }
    );
  };

  const redirect = (option) => {
    let optionToUse = selectedOption;
    if (option) {
      optionToUse = option;
    }
    if (optionToUse === options.yes) {
      window.location = "#/interview/confirmed";
    } else if (optionToUse === options.reschedule) {
      window.location = "#/interview/reschedule";
    } else if (optionToUse === options.no) {
      window.location = "#/interview/dropout";
    }
  };

  return (
    <div className="chatbot-container">{loading && <AnimatedLoader />}</div>
  );
};

export default Consent;
