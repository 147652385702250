import React, {useEffect, useState} from 'react';
import lbLogo from "./assets/images/logo-litmusBlox.svg";
import companyIcon from "./assets/images/company.png";
import checkWhite from "./assets/images/check-white.png";
import crossWhite from "./assets/images/cross-white.png";

import PoweredByLogo from "./components/common/PoweredByLogo";
import {useDispatch, useSelector} from "react-redux";
import {isNotEmpty, StringToHtml} from "./utils/Helper";
import {submitInterest} from "./services/InterestService";
import {urls} from "./utils/routes";
import setCandidateInterest from "./actions/InterestAction";


const StartConsent = ()=>{
    const dispatch = useDispatch();

    const {jobDetail, jobCandidateMapping, candidateInterest, candidateInterestDate} = useSelector(state=>state);
    const company = isNotEmpty(jobDetail)?jobDetail.companyId:{};
    const candidateName = isNotEmpty(jobCandidateMapping)?jobCandidateMapping.displayName:"";

    let floatingCardRef = null;

    const [descriptionMargin, setDescriptionMargin] = useState("");

    useEffect(()=>{
        if(candidateInterest){
            document.location = `#${urls.CHATBOT}`
        }
        else if(!candidateInterest && isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.DECLINE_THANKYOU}`
        }
        else if(candidateInterest && jobCandidateMapping.chatbotStatus === "Complete"){
            document.location = `#${urls.UPLOAD_RESUME}`
        }

        // set desciption height relative to other sections
        setDescriptionMargin(`${floatingCardRef.clientHeight}px`)
    }, []);

    return (
        <div className="chatbot-container">
            <div className="start-page-logo">

                <img src={ isNotEmpty(company.logo)?(process.env.REACT_APP_BASE + company?.logo):  lbLogo } width={200} alt={"start-consent-logo"}/>

            </div>
            <div className="job-details">
                <h4>{jobDetail.jobTitle}</h4>

                <div className="company-icon">
                    <span><img src={companyIcon} alt="company icon"/></span>
                    <span>{company.companyName}</span>
                </div>

                <div className="description" style={{marginBottom: descriptionMargin}}>
                    <h6>Description</h6>
                    {StringToHtml(jobDetail.jobDescription)}

                    <h6>About Company</h6>
                    {StringToHtml(company.companyDescription)}

                </div>

                <div className="floating-box" ref={(box)=>{floatingCardRef = box}}>
                    <p><span className="c-name">{candidateName}</span>, please take a minute to review the job description above.</p>
                    <span>Then hit the "I wish to apply" button to proceed.</span>
                    <div className="consent-buttons ">
                        <div className="apply-btn">
                            <span><img src={checkWhite} alt="checkWhite"/></span>
                            <input type="button" name="I wish to apply" value="I wish to apply" onClick={()=>{
                                dispatch(setCandidateInterest(true));
                                submitInterest();
                            }}/>
                        </div>
                        <div className="sorry-btn">
                            <span><img src={crossWhite} alt="cross White"/></span>
                            <input type="button" name="Sorry! Not interested" value="Sorry! Not interested" onClick={()=> {
                                dispatch(setCandidateInterest(false));
                                document.location = `#${urls.DECLINE}`
                            }}/>
                        </div>
                    </div>
                    <PoweredByLogo/>
                </div>
            </div>
        </div>
    )
};
export default StartConsent