import { string, func } from "prop-types";
import "./OtherOptionTextInput.css";
import infoIcon from "../../../assets/images/info-button.svg";

const OtherOptionTextInput = ({ inputValue, handleChange, validationText }) => {
  return (
    <>
      <input
        className={"other-answer-input-box"}
        onChange={(e) => handleChange(e.target.value)}
        value={inputValue}
        type="text"
        maxLength={"50"}
      />
      <div className="row">
        <p style={{ color: "red" }} className="col-10">
          <img
            src={infoIcon}
            style={{
              display: validationText ? "unset" : "none",
            }}
            alt="info-icon"
            height="14px"
            width="10px"
          />
          {validationText}
        </p>
        <p className="col-2 text-right">{inputValue.length}/50</p>
      </div>
    </>
  );
};

export default OtherOptionTextInput;

OtherOptionTextInput.propTypes = {
  inputValue: string.isRequired,
  handleChange: func.isRequired,
};
