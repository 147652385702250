import React, { useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import StartConsent from "./StartConsent";
import StartChatbotScreen from "./components/StartChatbotScreen";
import Home from "./components/Home";
import Chatbot from "./Chatbot";
import DeclineScreen from "./DeclineScreen1";
import DeclineThanks from "./DeclineThanks";
import DeclineReason from "./components/screens/DeclineReason";
import {
  getCandidateNotInterestedReasons,
  getChatbotData,
  getInterviewData,
  getUniversalChatbotData,
  setUniversalChatbotUuid,
} from "./services/DataService";
import { isNotEmpty } from "./utils/Helper";
import AnimatedLoader from "./components/common/AnimatedLoader";
import Confirmed from "./components/interviewConfirmation/Confirmed";
import Reschedule from "./components/interviewConfirmation/Reschedule";
import Dropout from "./components/interviewConfirmation/Dropout";
import AlreadyConfirmed from "./components/interviewConfirmation/AlreadyConfirmed";
import Consent from "./components/interviewConfirmation/Consent";
import { urls } from "./utils/routes";
import ResumeUploadWrapper from "./components/screens/ThankYouFlow/ResumeUploadWrapper";
import ReactGA from "react-ga";
import UniversalChatbotHome from "./components/UniversalChatbot/UChatbotHome";
import UniversalChatbotDecline from "./components/UniversalChatbot/UChatbotDecline";
import UniversalChatbotDeclineReason from "./components/UniversalChatbot/UChatbotDeclineReason";
import UniversalChatbotDeclineThanks from "./components/UniversalChatbot/UChatbotDeclineThanks";
import UniversalChatbotSections from "./components/UniversalChatbot/UChatbotSections";
import UChatbotConsent from "./components/UniversalChatbot/UChatbotConsent";
import UChatbotResumeWrapper from "./components/UniversalChatbot/UChatbotResumeWrapper";
import UChatbotThankYouWrapper from "./components/UniversalChatbot/UChatbotThankYouWrapper";
import ThankYouWrapper from "./components/screens/ThankYouFlow/ThankYouWrapper";
import { setIsUniversalChatbot } from "./actions/UChatbotDataActions";
import store from "./utils/Store";
import PageNotFound from "./404";

const reactGATrackingCode = process.env.REACT_APP_GA_ANALYTICS_TRACKING_CODE;

export const App = () => {
  const {
    uuid,
    jobDetail,
    hrQuestions,
    interviewDetail,
    jobCandidateMapping,
    showLoader,
    interviewConfirmation,
    chatbotErrorMessage,
  } = useSelector((state) => state);
  const candidateName = isNotEmpty(jobCandidateMapping)
    ? jobCandidateMapping.candidateFirstName
    : undefined;

  useEffect(() => {
    if (window.location.hash.includes("interview")) {
      getInterviewData(window.location.hash);
    } else if (window.location.hash.includes("universal")) {
      store.dispatch(setIsUniversalChatbot(true));
      setUniversalChatbotUuid(window.location.hash);
      getCandidateNotInterestedReasons();
      getUniversalChatbotData();
    } else {
      getChatbotData(window.location.hash);
    }
    ReactGA.initialize(reactGATrackingCode);
    ReactGA.pageview(window.location.hash + window.location.search);
  }, []);

  return [
    showLoader && <AnimatedLoader />,
    isNotEmpty(candidateName) &&
      ((jobDetail.hrQuestionAvailable &&
        isNotEmpty(hrQuestions) &&
        hrQuestions.length > 0) ||
        (!jobDetail.hrQuestionAvailable &&
          jobDetail.jobSkillsAttributesList?.length > 0)) && (
        <HashRouter className="App">
          <Switch>
            <Route exact path={urls.CONSENT} component={StartConsent} />
            <Route
              exact
              path={urls.START_CHATBOT_CONSENT}
              component={StartChatbotScreen}
            />
            <Route exact path={urls.DECLINE} component={DeclineScreen} />
            <Route path={urls.DECLINE_REASON} component={DeclineReason} />
            <Route path={urls.DECLINE_THANKYOU} component={DeclineThanks} />
            <Route path={urls.CHATBOT} component={Chatbot} />
            <Route path={urls.UPLOAD_RESUME} component={ResumeUploadWrapper} />
            <Route path={urls.THANKYOU} component={ThankYouWrapper} />
            <Route exact path={"/:uuid"} component={Home} />
            <Route path={"*"} component={PageNotFound} />
          </Switch>
        </HashRouter>
      ),
    isNotEmpty(interviewDetail) &&
      Object.keys(interviewDetail).length > 0 &&
      isNotEmpty(interviewConfirmation) &&
      interviewConfirmation.length > 0 &&
      isNotEmpty(uuid) && (
        <HashRouter className="App">
          <Switch>
            <Route exact path={"/interview/confirmed"} component={Confirmed} />
            <Route
              exact
              path={"/interview/reschedule"}
              component={Reschedule}
            />
            <Route exact path={"/interview/dropout"} component={Dropout} />
            <Route
              exact
              path={"/interview/alreadyconfirmed"}
              component={AlreadyConfirmed}
            />
            <Route exact path={"/interview/:uuid/"} component={Consent} />
            <Route path={"*"} component={PageNotFound} />
          </Switch>
        </HashRouter>
      ),
      (
        isNotEmpty(chatbotErrorMessage) &&
        <HashRouter className="App">
          <Switch>
            <Route path={"*"} component={PageNotFound} />
          </Switch>
        </HashRouter>
      ),
      (
        !isNotEmpty(chatbotErrorMessage) &&
        <HashRouter className="App">
        <Switch>
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_DECLINE}
            component={UniversalChatbotDecline}
          />
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_DECLINE_REASON}
            component={UniversalChatbotDeclineReason}
          />
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}
            component={UniversalChatbotDeclineThanks}
          />
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_CONSENT}
            component={UChatbotConsent}
          />
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_SECTIONS}
            component={UniversalChatbotSections}
          />
          <Route
            path={urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}
            component={UChatbotResumeWrapper}
          />
          <Route
            path={urls.UNIVERSAL_CHATBOT_THANKYOU}
            component={UChatbotThankYouWrapper}
          />
          <Route
            exact
            path={urls.UNIVERSAL_CHATBOT_HOME}
            component={UniversalChatbotHome}
          />
        </Switch>
      </HashRouter>
      ),
   
  ];
};

export default App;
