import { func, array } from "prop-types";

import { renderAnswerTypes } from "../../../utils/Constant";
import { capitalizeFirstLetter, isNotEmpty } from "../../../utils/Helper";
import CalendarInput from "../../answerTypes/calendar";
import Dropdown from "../../answerTypes/Dropdown";
import Salary from "../../answerTypes/salary";
import YesNoRadioButton from "../../answerTypes/yesNoRadioButton";
import TextInput from "../../answerTypes/TextInput";
import CheckBoxWrapper from "../../answerTypes/Checkbox/CheckBoxWrapper";
import RadioButtonWrapper from "../../answerTypes/RadioButton/RadioButtonWrapper";
import { UChatbotQs } from "../../../utils/UChatbotConstants";
import { questionObjType } from "../../../types";
import TopSkillsTextBoxes from "../../answerTypes/TopSkillsTextBoxes";

const Answer = ({ handleSubmit, questionObj, optionsList }) => {
  const isYesNo = (options) => {
    return (
      options &&
      options.length === 2 &&
      options.every((option) => ["yes", "no"].includes(option.toLowerCase()))
    );
  };

  const isTopSkillsQs = (questionTag) => {
    return questionTag === UChatbotQs.TOP_SKILLS_TAG;
  };

  let answerJSX = <div />;

  if (isNotEmpty(questionObj?.ansType)) {
    switch (questionObj?.ansType) {
      case renderAnswerTypes.CHECK_BOX:
        answerJSX = (
          <CheckBoxWrapper
            handleSubmit={handleSubmit}
            options={optionsList || []}
            isScreeningQuestion={questionObj?.screeningQuestions}
            anyN={questionObj?.anyN}
          />
        );
        break;
      case renderAnswerTypes.INPUT_BOX:
        if (isTopSkillsQs(questionObj?.tag)) {
          answerJSX = <TopSkillsTextBoxes handleSubmit={handleSubmit} />;
        } else {
          answerJSX = (
            <TextInput handleSubmit={handleSubmit} tag={questionObj?.tag} />
          );
        }
        break;
      case renderAnswerTypes.RADIO_BUTTON:
        if (isYesNo(questionObj.options)) {
          answerJSX = <YesNoRadioButton handleSubmit={handleSubmit} />;
        } else {
          answerJSX = (
            <RadioButtonWrapper
              options={optionsList || []}
              handleSubmit={handleSubmit}
              multiLevelOptions={
                isNotEmpty(questionObj.multiLevelOptions)
                  ? JSON.parse(questionObj.multiLevelOptions)
                  : {}
              }
              isScreeningQuestion={questionObj?.screeningQuestions}
            />
          );
        }
        break;
      case renderAnswerTypes.FILTER_DROPDOWN:
        answerJSX = (
          <Dropdown
            masterDataKey={optionsList.length > 0 ? optionsList[0] : ""}
            handleSubmit={handleSubmit}
            placeHolder={
              optionsList.length > 0
                ? `Select ${capitalizeFirstLetter(optionsList[0])}...`
                : ""
            }
            dropdownClassName={
              optionsList.length > 0 ? `${optionsList[0]} dropdown` : "dropdown"
            }
          />
        );
        break;
      case renderAnswerTypes.SLIDER:
        answerJSX = <Salary handleSubmit={handleSubmit} />;
        break;
      case renderAnswerTypes.LOCATION:
        answerJSX = (
          <RadioButtonWrapper
            options={questionObj.options || []}
            handleSubmit={handleSubmit}
            multiLevelOptions={
              isNotEmpty(questionObj.multiLevelOptions)
                ? JSON.parse(questionObj.multiLevelOptions)
                : {}
            }
          />
        );
        break;
      case renderAnswerTypes.CALENDAR:
        answerJSX = (
          <CalendarInput
            handleSubmit={handleSubmit}
            questionType={questionObj.ansType}
          />
        );
        break;
      case renderAnswerTypes.FUTURE_CALENDAR:
        answerJSX = (
          <CalendarInput
            handleSubmit={handleSubmit}
            questionType={questionObj.ansType}
          />
        );
        break;
      case renderAnswerTypes.PAST_CALENDAR:
        answerJSX = (
          <CalendarInput
            handleSubmit={handleSubmit}
            questionType={questionObj.ansType}
          />
        );
        break;
      default:
        console.error("Answer Type not matched");
    }
  }

  return <div>{answerJSX}</div>;
};

export default Answer;

Answer.propTypes = {
  questionObj: questionObjType,
  handleSubmit: func.isRequired,
  optionsList: array,
};
