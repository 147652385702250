const baseURL = process.env.REACT_APP_BASE_URL;
const noAuthUrl = baseURL + 'noAuth/'
const uChatbotResponseUrl = baseURL + 'uchat-bot-response/'

export const apiUrls = {
    fetchChatbotQuestions : noAuthUrl + 'screeningQuestion',
    candidateInterest : noAuthUrl + 'candidateInterest',
    sendChatbotAnswers : noAuthUrl + 'screeningQuestionResponse',
    fetchCandidateAndJobDetails: noAuthUrl + 'candidateAndJobDetails',
    chatbotDetails: noAuthUrl + 'chatbotDetails',
    fetchMasterDataURL: noAuthUrl + "fetch/items",
    interviewDetail: noAuthUrl + "getCandidateConfirmationStatus",
    interviewConfirmationURL: noAuthUrl + "candidateConfirmation",
    uploadResumeURL: noAuthUrl + 'uploadResume',
    fetchUniversalChatbotQuestions : noAuthUrl + 'uchat-bot-questions',
    saveTemplateQsResponse : uChatbotResponseUrl + 'template',
    saveDefaultQsResponse : uChatbotResponseUrl + 'default-questions',
    saveScreeningQsResponse : uChatbotResponseUrl + 'screening',
    uChatbotCandidateInterest: uChatbotResponseUrl + 'candidate-interest',
    uChatbotUploadResume: noAuthUrl + 'ccm-upload-resume',
};