import {RECEIVE_INTERVIEW_CONFIRMATION, RECEIVE_INTERVIEW_DATA} from '../../actions/DataAction';


export const interviewDetail = (interviewDetail={}, action) => {
    switch (action.type) {
        case RECEIVE_INTERVIEW_DATA:
            return {...interviewDetail, ...action.interviewData};
        default:
            return interviewDetail
    }
};

export const interviewConfirmation = (interviewConfirmationOptions=[], action) => {
    switch (action.type) {
        case RECEIVE_INTERVIEW_CONFIRMATION:
            return [...interviewConfirmationOptions, ...action.interviewConfirmationOptions];
        default:
            return interviewConfirmationOptions;
    }
};
