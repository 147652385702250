import { func, string } from "prop-types";

const ButtonOrange = ({onClick, btnText})=>(
    <div className="button-orange">
        <button onClick={onClick}>{btnText}</button>
    </div>
);
export default ButtonOrange;

ButtonOrange.propTypes = {
  onClick: func.isRequired,
  btnText: string.isRequired,
};