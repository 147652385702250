import axios from 'axios'
import {isNotEmpty} from "../utils/Helper";

export function postAjaxCall (api, inputParamJSON, callbackFn) {
  const config = {
    method: 'post',
    data: inputParamJSON,
    url: api,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  axios
    .request(config)
    .then(function (response) {
      if (callbackFn) {
        callbackFn(response.data, null)
      } else {
        return response.data
      }
    })
    .catch(error => {
      if (isCallBackError(callbackFn, error)) {
        callbackFn(null, error.response.data)
      }
    })
}

export function postAjaxCallHybrid(api, file, data, callbackbn){
  let formData = new FormData();
  if(file!==null) {
    formData.append("candidateCv", file);
  }
  formData.append("chatbotUuid", data);
  postAjaxCall(api, formData, callbackbn);
}

export function getAjaxCall (api, callbackFn) {
  const config = {
    method: 'get',
    url: api,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  axios
    .request(config)
    .then(function (response) {
      if (callbackFn) {
        callbackFn(response.data, null)
      } else {
        return response.data
      }
    })
    .catch(error => {
      if (isCallBackError(callbackFn, error)) {
        callbackFn(null, error.response.data)
      }
    })
}

export function getAjaxCallWithoutToken (api, callbackFn) {
  const config = {
    method: 'get',
    url: api,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  axios
    .request(config)
    .then(function (response) {
      if (callbackFn) {
        callbackFn(response.data, null)
      } else {
        return response.data
      }
    })
    .catch(error => {
      if (isCallBackError(callbackFn, error)) {
        callbackFn(null, error.response.data)
      }
    })
}

export function putAjaxCall(api, callbackFn) {
  const config = {
    method: "put",
    url: api,
    headers: {
      "Content-Type": "application/json",
    }
  };

  axios
      .request(config)
      .then(function(response) {
        if (callbackFn) {
          callbackFn(response.data, null);
        } else {
          return response.data;
        }
      })
      .catch(error => {
        console.error("request error:" + error);
        if (callbackFn) {
          callbackFn(null, error.response.data);
        }
      });
}

export function putAjaxCallWithData (api, inputParamJSON, callbackFn) {
  const config = {
    method: 'put',
    data: inputParamJSON,
    url: api,
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin" : "*",
    }
  }

  axios
      .request(config)
      .then(function (response) {
        if (callbackFn) {
          callbackFn(response.data, null)
        } else {
          return response.data
        }
      })
      .catch(error => {
        if (isCallBackError(callbackFn, error)) {
          callbackFn(null, error.response.data)
        }
      })
}

function isCallBackError (callbackFn, error) {
  console.error('request error:' + error)
  if (callbackFn && isNotEmpty(error) && isNotEmpty(error.response)) {
    return true
  }
  return false
}
