import React, {useEffect} from 'react';
import linedIn from './assets/images/linkedin.png';
import facebook from './assets/images/fb-icon.png';
import PoweredByLogo from "./components/common/PoweredByLogo";
import {isNotEmpty} from "./utils/Helper";
import {useSelector} from "react-redux";
import {urls} from "./utils/routes";


const DeclineThanks = ()=> {
    const {jobCandidateMapping, jobDetail, candidateInterest, candidateInterestDate} = useSelector(state => state);
    const candidateName = isNotEmpty(jobCandidateMapping) ? jobCandidateMapping.displayName : "";
    const companyName = isNotEmpty(jobDetail) && jobDetail.hasOwnProperty("company")?jobDetail.company.companyName:"";

    useEffect(()=>{
        if(candidateInterest){
            document.location = `#${urls.CHATBOT}`
        }
        else if(!candidateInterest && !isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.CONSENT}`
        }
        else if(candidateInterest && jobCandidateMapping.chatbotStatus === "Complete"){
            document.location = `#${urls.UPLOAD_RESUME}`
        }
    }, [candidateInterest, candidateInterestDate]);

    return (
        <div className="chatbot-container Hr-selection-screen bottom-container">
            <h4>
                <span className="c-name">{candidateName},</span> got it and thanks nevertheless!.
            </h4>
            <p>
                An increasing number of companies are using {companyName} for making the candidate application process
                smoother.
            </p>
            <p>
                You may be invited by other companies and asked to complete a quick profiling via a chatbot like this.
                Be on the lookout.
            </p>
            <p>
                Best wishes for your career and I hope our paths cross again in the future!
            </p>
            <div className="social-links">
            <span onClick={()=>window.open('https://www.linkedin.com/company/litmusblox?originalSubdomain=in', '_blank')}>
                <img src={linedIn} alt="LinkedIn"/>
            </span>
                <span onClick={()=>window.open('https://www.facebook.com/litmusblox/', '_blank')}>
                <img src={facebook} alt="Facebook"/>
            </span>
                <PoweredByLogo/>
            </div>
        </div>
    )
};

export default DeclineThanks;