import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isNotEmpty} from "../../utils/Helper";
import Index from "../answerTypes/RadioButton/index";
import {setDeclineReason} from "../../actions/DeclineReasonActions";
import PoweredByLogo from "../common/PoweredByLogo";
import OKButton from "../OKButton";
import {submitInterest} from "../../services/InterestService";
import {urls} from "../../utils/routes";


const DeclineReason = () => {
    const dispatch = useDispatch();
    const {
        declineReason,
        notInterestedReasons,
        chatbotContent,
        candidateInterest,
        candidateInterestDate,
        jobCandidateMapping
    } = useSelector(state=>state);
    const declineReasonText = chatbotContent.hasOwnProperty("declineReasonText")? chatbotContent.declineReasonText:
        "Can you help me with the reason for declining this opportunity? Select the most appropriate one";

    useEffect(()=>{
        if(candidateInterest){
            document.location = `#${urls.CHATBOT}`
        }
        else if(!candidateInterest && isNotEmpty(candidateInterestDate)){
            document.location = `#${urls.DECLINE_THANKYOU}`
        }
        else if(candidateInterest && jobCandidateMapping.chatbotStatus === "Complete"){
            document.location = `#${urls.UPLOAD_RESUME}`
        }
    }, []);

    return (
        <div className={'chatbot-container  Hr-selection-screen bottom-container'}>

                <h4>Your reason for decline?</h4>
               
                <p>{declineReasonText}</p>

            <p className={"question-breadcrumb mb-0"}>Select any one option</p>
            {
                isNotEmpty(notInterestedReasons) && Object.keys(notInterestedReasons).map((reasonId, index)=>(
                    <Index
                        key={`${reasonId}_${index}`}
                        value={reasonId}
                        checked={reasonId===declineReason}
                        handleChange={()=>dispatch(setDeclineReason(reasonId))}
                        label={notInterestedReasons[reasonId]}
                    />
                ))
            }
            <br />
            <OKButton disabled={!isNotEmpty(declineReason)} btnText={"Submit"} onClick={()=>submitInterest()}/>
            <PoweredByLogo/>
        </div>
    )
};

export default DeclineReason;