export const urls = {
    HOME:                                   '/home',
    CONSENT:                                '/consent',
    DECLINE:                                '/decline',
    DECLINE_REASON:                         '/decline/reason',
    DECLINE_THANKYOU:                       '/decline/thankyou',
    START_CHATBOT_CONSENT:                  '/chatbot/consent',
    CHATBOT:                                '/chatbot',
    UPLOAD_RESUME:                          '/upload/resume',
    THANKYOU:                               '/thankyou',
    UNIVERSAL_CHATBOT_HOME:                 '/universal/:uuid',
    UNIVERSAL_CHATBOT_DECLINE:              '/universal/decline',
    UNIVERSAL_CHATBOT_DECLINE_REASON:       '/universal/decline/reason',
    UNIVERSAL_CHATBOT_DECLINE_THANKYOU:     '/universal/decline/thankyou',
    UNIVERSAL_CHATBOT_CONSENT:              '/universal/consent',
    UNIVERSAL_CHATBOT_SECTIONS:             '/universal/chatbot',
    UNIVERSAL_CHATBOT_THANKYOU:             '/universal/thankyou',
    UNIVERSAL_CHATBOT_UPLOAD_RESUME:        '/universal/upload-resume',
};