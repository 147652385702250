import { number } from 'prop-types';
import {ProgressBar as ProgressBarBootstrap} from 'react-bootstrap';

const ProgressBar = ({complete, total}) => {
    let value = Math.round((complete/total)*100);
    return (
        <div>
            <p style={{fontSize: '12px', textAlign: 'right', marginBottom: '-2px', display: 'block', color:'#000'}}>{complete}/{total} questions</p>
            <ProgressBarBootstrap style={{width: '100%', height: '6px',backgroundColor:'#EFEFEF'}} variant="custom" now={value}/>
        </div>
    )
};

export default ProgressBar

ProgressBar.propTypes = {
  complete: number.isRequired,
  total: number.isRequired,
};
