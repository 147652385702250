import React from 'react';
import {isNotEmpty, StringToHtml} from "../../utils/Helper";
import {useSelector} from "react-redux";

const Question = ({questionText, smallText, skill}) => {
    const {jobDetail} = useSelector(state => state);
    const quickQuestion = jobDetail?.quickQuestion;
    const statementBlock = jobDetail?.statementBlock;
    const companyName = isNotEmpty(jobDetail) && jobDetail.hasOwnProperty("companyId")?jobDetail.companyId.companyName:"";

    return (
              quickQuestion && questionText===undefined?
                  <>
                      <h3 className="skills-text">{skill}</h3>
                      <h4 className="q-text">{statementBlock?.question}</h4>
                  </>
                  :
                  <>
                      <p className={"question-breadcrumb"}><i>{smallText}</i></p>
                      <h4 className="q-text">{StringToHtml(questionText.replace('`$companyName`', companyName))}</h4>
                  </>
    )
};
 export default Question;