import {
  SET_IS_UNIVERSAL_CHATBOT,
  SET_UCHATBOT_ANSWERS,
  SET_UCHATBOT_DETAILS,
  SET_UCHATBOT_FLAGS,
  SET_UCHATBOT_PHASE,
  SET_UCHATBOT_QUESTIONS,
} from "../../actions/UChatbotDataActions";

const initialChatbotFlags = {
  templateQsCompletedFlag: false,
  defaultQsCompletedFlag: false,
  hrQsCompletedFlag: false,
  customQsCompletedFlag: false,
};

const initialChatbotAnswers = {
  templateAnswers: {},
  defaultAnswers: {},
  screeningAnswers: {},
};

export const uChatbotPhase = (uChatbotPhase = null, action) => {
  switch (action.type) {
    case SET_UCHATBOT_PHASE:
      return action.uChatbotPhase;
    default:
      return uChatbotPhase;
  }
};

export const uChatbotDetails = (uChatbotDetails = {}, action) => {
  switch (action.type) {
    case SET_UCHATBOT_DETAILS:
      return { ...uChatbotDetails, ...action.uChatbotDetails };
    default:
      return uChatbotDetails;
  }
};

export const uChatbotQuestions = (uChatbotQuestions = {}, action) => {
  switch (action.type) {
    case SET_UCHATBOT_QUESTIONS:
      return { ...uChatbotQuestions, ...action.uChatbotQuestions };
    default:
      return uChatbotQuestions;
  }
};

export const uChatbotAnswers = (
  uChatbotAnswers = initialChatbotAnswers,
  action
) => {
  switch (action.type) {
    case SET_UCHATBOT_ANSWERS:
      return { ...uChatbotAnswers, ...action.uChatbotAnswers };
    default:
      return uChatbotAnswers;
  }
};

export const isUniversalChatbot = (isUniversalChatbot = false, action) => {
  switch (action.type) {
    case SET_IS_UNIVERSAL_CHATBOT:
      return action.isUniversalChatbot;
    default:
      return isUniversalChatbot;
  }
};

export const uChatbotFlags = (uChatbotFlags = initialChatbotFlags, action) => {
  switch (action.type) {
    case SET_UCHATBOT_FLAGS:
      return action.uChatbotFlags;
    default:
      return uChatbotFlags;
  }
};
