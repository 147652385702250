import { useEffect } from "react";
import { Transition } from "react-transition-group";
import { node } from "prop-types";

const CommonTransition = ({ children }) => {
  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <Transition in={true} timeout={600} appear unmountOnExit={false}>
        {(status) => <div className={`box box-${status}`}>{children}</div>}
      </Transition>
    </>
  );
};

export default CommonTransition;

CommonTransition.propTypes = {
  children: node.isRequired,
};
