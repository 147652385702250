import { func, number } from 'prop-types';
import './range.css';

const Range = ({ value, onChange }) => {

    return (
        <div className={ " range-container " }>
            <input
                type={'button'}
                onClick = { () => {
                    if(value<9)
                        onChange( value + 1 );
                }}
                className={ "range-button"}
                value={'+'}
            />
            <input
                value={value}
                onChange={
                    ( event ) => {
                        if(
                            (parseInt(event.target.value)>=0 && parseInt(event.target.value)<=9)
                            ||
                            isNaN(parseInt(event.target.value))
                        ) {
                            onChange(isNaN(parseInt(event.target.value))?0:parseInt(event.target.value))
                        }
                    }
                }
                placeholder={'0'}
                className={"range-input"}
            />
            <input
                type={'button'}
                onClick={ () => {
                    if(value>0)
                        onChange( value - 1 );
                }}
                className={ "range-button" }
                value={'-'}
            />
        </div>
    )
};

export default Range;

Range.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
};