import { bool, func, string } from "prop-types";
import "./checkbox.css";
import checkmark from "./../../../assets/images/checkmark.svg";
import {StringToHtml} from "../../../utils/Helper";

const Checkbox = ({ checked, onChange, label }) => { 
  
  return (
    <div    
      onClick={()=>onChange(label)}
      className={"checkbox " + (checked ? "checked" : "")}
    >
      <div className="border">
        <div className="indicator">
          {checked && <img src={checkmark} alt="checkmark" />}
        </div>
      </div>      
      <div className="label">
          {
            /<[a-zA-Z]*>[\s\S]*<\/[a-zA-Z]*>/g.test(label)?
              StringToHtml(label)
              :
              label
          }
          </div>
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
  label: string.isRequired,
};