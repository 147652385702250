import React from 'react';
import {isNotEmpty} from "../../utils/Helper";
import {getValue} from "../../services/CookieService";
import {uuidMatcher} from "../../utils/Constant";
import ThankyouSection from "./ThankyouSection";
import {getInterviewData} from "../../services/DataService";
import {useSelector} from "react-redux";
import thanksImage from "../../assets/images/thanks-img.png";
import PoweredByLogo from "../common/PoweredByLogo";

const AlreadyConfirmed = (props)=> {

    const interviewDetail = useSelector(state => state.interviewDetail);

    if (!isNotEmpty(interviewDetail)) {
        if (!isNotEmpty(getValue('interviewUuid'))&& uuidMatcher.test(getValue('interviewUuid'))) {
            getInterviewData(getValue('interviewUuid'));
        }
    }
    return (
        isNotEmpty(interviewDetail) &&
        <div className="chatbot-container">
            <p className=" mt-3" style={{fontSize:"21px",color:"#000"}}>
                <span style={{textTransform:"capitalize"}}><b>{interviewDetail.displayName}</b></span>,<span> Your response has been recorded!</span>
            </p>
            <br/>
            <p>We have received and recorded your response earlier. <br /><br/>Thank you again.</p>
            <div className="row mt-5">
                <img alt="thank-you" className="thank-you-image" src={thanksImage} />
            </div>

                <ThankyouSection/>

            <PoweredByLogo/>
        </div>
    )
};

export default AlreadyConfirmed;