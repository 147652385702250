import { postAjaxCall } from './AjaxService.jsx'
import { getValueFromSession, setValueInSession } from './SessionStorageService.jsx'
import { apiUrls } from './ApiUrls'
import { getValueFromLocalStorage, setValueInLocalStorage } from './LocalstorageService'


export function getMasterData (fromLocalStorage, keys, callbackFn) {
    // masterData will have result masterData; missingData will contain masterData not present in local storage
    var masterData = []; var missingData = [];
    for (var i = 0; i < keys.length; i++) {
        var storedValue = "";
        if(fromLocalStorage) {
            storedValue = getValueFromLocalStorage(keys[i])
        } else {
            storedValue = getValueFromSession(keys[i]) // Get value from local storage
        }
        // If value present in local storage, push it to masterData, Else push it to missingData
        if (storedValue !== null) {
            masterData[keys[i]] = storedValue
        } else {
            missingData.push(keys[i])
        }
    }
    // If there is some data missing, get it from server, Else return
    if (missingData.length > 0) {
        postAjaxCall(apiUrls.fetchMasterDataURL, missingData, function (json, error) {
            if (error == null) {
                for (var j = 0; j < missingData.length; j++) {
                    if(fromLocalStorage) {
                        setValueInLocalStorage(missingData[j], json[missingData[j]])
                    } else {
                        setValueInSession(missingData[j], json[missingData[j]])
                    }
                    masterData[missingData[j]] = json[missingData[j]]
                }
                if (callbackFn) {
                    callbackFn(masterData, null)
                }
                else{
                    return masterData;
                }
            }
            else{
                if(callbackFn){
                    callbackFn(null, error)
                }
                else
                    return error;
            }
        })
    } else {
        if (callbackFn) {
            callbackFn(masterData, null)
        }
        else{
            return masterData
        }
    }
}
