export const SET_CANDIDATE_INTEREST = "SET_CANDIDATE_INTEREST";
export const SET_CANDIDATE_INTEREST_DATE = "SET_CANDIDATE_INTEREST_DATE";
export const SET_CANDIDATE_NOT_INTERESTED_REASONS = "SET_CANDIDATE_NOT_INTERESTED_REASONS";
export const SET_CANDIDATE_UNIVERSAL_NOT_INTERESTED_REASONS = "SET_CANDIDATE_UNIVERSAL_NOT_INTERESTED_REASONS";

export const setCandidateInterest = (candidateInterest)=>{
    return{
        type: SET_CANDIDATE_INTEREST,
        candidateInterest
    }
};

export const setCandidateInterestDate = (date) => {
    return {
        type: SET_CANDIDATE_INTEREST_DATE,
        date
    }
};

export const setCandidateNotInterestedReason = (data) => {
    return {
        type: SET_CANDIDATE_NOT_INTERESTED_REASONS,
        data
    }
};

export const setCandidateNotInterestedReasonUniversal = (data) => {
    return {
        type: SET_CANDIDATE_UNIVERSAL_NOT_INTERESTED_REASONS,
        data
    }
};

export default setCandidateInterest;