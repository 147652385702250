import { useEffect, useState } from "react";
import lbLogo from "../../assets/images/logo-litmusBlox.svg";
import companyIcon from "../../assets/images/company.png";
import checkWhite from "../../assets/images/check-white.png";
import crossWhite from "../../assets/images/cross-white.png";

import { useSelector, useDispatch } from "react-redux";

import { uChatbotSubmitInterest } from "../../services/InterestService";
import { uChatbotPhases } from "../../utils/UChatbotConstants";
import PoweredByLogo from "../common/PoweredByLogo";
import { urls } from "../../utils/routes";
import { isNotEmpty, StringToHtml } from "../../utils/Helper";
import setCandidateInterest from "../../actions/InterestAction";
import { showLoader } from "../../actions/LoaderAction";

const UChatbotConsent = () => {
  const dispatch = useDispatch();

  const {
    uChatbotStatus,
    candidateInterest,
    candidateInterestDate,
    uChatbotDetails,
  } = useSelector((state) => state);
  const {
    companyDescription,
    campaignDescription,
    companyName,
    companyLogo,
    campaignTitle,
    candidateFirstName,
    candidateLastName,
    questionToAsk,
  } = uChatbotDetails;

  let floatingCardRef = null;

  const [descriptionMargin, setDescriptionMargin] = useState("");
  const [reviewDescription, setReviewDescription] = useState(
    StringToHtml(
      questionToAsk?.reviewDescription
        .replace(
          "$candidateName",
          `<b className="c-name">${candidateFirstName} ${candidateLastName}</b>`
        )
        .replace("\\n", "<br/>")
        .replace(
          "Then hit “I wish to apply” button to process.",
          '<div class="c-name-description">Then hit “I wish to apply” button to process.</div>'
        ),
      "p"
    )
  );
  const [renderConsentJSX, setRenderConsentJSX] = useState(false);

  useEffect(() => {
    dispatch(showLoader(true));
  }, []);

  useEffect(() => {
    if (candidateInterest && uChatbotStatus !== uChatbotPhases.COMPLETE) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    } else if (
      candidateInterest &&
      uChatbotStatus === uChatbotPhases.COMPLETE
    ) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    } else {
      setTimeout(() => {
        dispatch(showLoader(false));
        setRenderConsentJSX(true);
      }, 600);
    }

    // set desciption height relative to other sections
    setDescriptionMargin(`${floatingCardRef?.clientHeight}px`);
  }, [candidateInterest, candidateInterestDate, uChatbotStatus]);

  if (renderConsentJSX) {
    return (
      <div className="chatbot-container">
        <section className="start-page-logo">
          <img
            src={
              isNotEmpty(companyLogo)
                ? process.env.REACT_APP_BASE + companyLogo
                : lbLogo
            }
            width={200}
            alt={"start-consent-logo"}
          />
        </section>
        <section className="job-details">
          <h4>{campaignTitle}</h4>

          <div className="company-icon">
            <span>
              <img src={companyIcon} alt="company icon" />
            </span>
            <span>{companyName}</span>
          </div>

          <section
            className="description"
            style={{ marginBottom: descriptionMargin }}
          >
            <h6>Description</h6>
            {StringToHtml(campaignDescription)}

            <h6>About Company</h6>
            {StringToHtml(companyDescription)}
          </section>

          <section
            className="floating-box"
            ref={(box) => {
              floatingCardRef = box;
            }}
          >
            {reviewDescription}
            <div className="consent-buttons ">
              <div className="apply-btn">
                <span>
                  <img src={checkWhite} alt="check white icon" />
                </span>
                <input
                  type="button"
                  name="Yes, I wish to apply!"
                  value="Yes, I wish to apply!"
                  onClick={() => {
                    dispatch(setCandidateInterest(true));
                    uChatbotSubmitInterest();
                  }}
                />
              </div>
              <div className="sorry-btn">
                <span>
                  <img src={crossWhite} alt="cross white icon" />
                </span>
                <input
                  type="button"
                  name="Sorry! Not interested"
                  value="Sorry! Not interested"
                  onClick={() => {
                    dispatch(setCandidateInterest(false));
                    document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE}`;
                  }}
                />
              </div>
            </div>
            <PoweredByLogo />
          </section>
        </section>
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default UChatbotConsent;
