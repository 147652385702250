import {
    SET_CANDIDATE_INTEREST,
    SET_CANDIDATE_INTEREST_DATE,
    SET_CANDIDATE_NOT_INTERESTED_REASONS,
    SET_CANDIDATE_UNIVERSAL_NOT_INTERESTED_REASONS
} from "../actions/InterestAction";

export const candidateInterest = (candidateInterest = false, action) => {
    switch (action.type) {
        case SET_CANDIDATE_INTEREST:
            return action.candidateInterest;
        default:
            return candidateInterest;
    }
};

export const candidateInterestDate = (date=null, action) => {
    switch (action.type) {
        case SET_CANDIDATE_INTEREST_DATE:
            return action.date;
        default:
            return date;
    }
};

export const notInterestedReasons = (notInterestedReasons = {}, action) => {
    switch (action.type) {
        case SET_CANDIDATE_NOT_INTERESTED_REASONS:
            return {...notInterestedReasons, ...action.data};
        default:
            return notInterestedReasons
    }
};

export const notInterestedReasonsUniversal = (notInterestedReasonsUniversal = {}, action) => {
    switch (action.type) {
        case SET_CANDIDATE_UNIVERSAL_NOT_INTERESTED_REASONS:
            return {...notInterestedReasonsUniversal, ...action.data};
        default:
            return notInterestedReasonsUniversal
    }
};