import React, {useEffect} from 'react';
import {isNotEmpty} from "../../utils/Helper";
import {getValue} from "../../services/CookieService";
import {uuidMatcher} from "../../utils/Constant";
import ThankyouSection from "./ThankyouSection";
import {useSelector} from "react-redux";
import {getInterviewData} from "../../services/DataService";
import thanksImage from "../../assets/images/thanks-img.png";
import PoweredByLogo from "../common/PoweredByLogo";

const Confirmed = ()=> {
    const interviewDetail = useSelector(state=>state.interviewDetail);

    useEffect(()=>{
        if(isNotEmpty(interviewDetail.candidateConfirmationTime)){
            document.location = '#/interview/alreadyconfirmed'
        }
    }, []);

    if(!isNotEmpty(interviewDetail)){
        if(!isNotEmpty(getValue('interviewUuid')) && uuidMatcher.test(getValue('interviewUuid'))) {
            getInterviewData(getValue('interviewUuid'));
        }
    }
    return(
        isNotEmpty(interviewDetail) &&
        <div className="chatbot-container">
            <h4 className="mt-3 mb-2"style={{fontWeight:"normal",fontFamily:"Apercu-Pro-Regular"}} >
                Awesome!
            </h4>
            <p className="mt-4">Thanks for your confirmation and get prepared for the interview. You will shortly
                receive an email with the details.
                <br />
                <b>See you soon!</b>
            </p>
            <div className="row mt-5">
                <img alt="thank-you" className="thank-you-image" src={thanksImage} />
            </div>

                <ThankyouSection/>
                <PoweredByLogo/>

        </div>
    )
};

export default Confirmed;