import { isNotEmpty } from '../utils/Helper'

export function setValueInSession (key, value) {
  return sessionStorage.setItem(key, JSON.stringify(value))
}

export function getValueFromSession (key) {
  if (isNotEmpty(sessionStorage.getItem(key))) {
    return JSON.parse(sessionStorage.getItem(key))
  }
  return null
}

export function removeValueFromSession (key) {
  if (sessionStorage.getItem(key)) {
    return sessionStorage.removeItem(key)
  }
  return null
}

export function clearSession() {
  return sessionStorage.clear()
}
