import { bool, string, func } from "prop-types";
import './RadioButton.css'
import {StringToHtml} from "../../../utils/Helper";

const Radio = ({checked, label,value, handleChange}) => {
    return (
        <div >            
            <label
                className={checked ? 'checked' : 'unChecked'}
            >
                <input
                    className={checked ? 'radio-checked': 'radio-unchecked'}
                    type="radio"
                    value={value}
                    name="size"
                    onClick={()=>handleChange(value)}
                />
                <span
                    className="radio-text"
                >
                    {
                        /<[a-zA-Z]*>[\s\S]*<\/[a-zA-Z]*>/g.test(label)?
                            StringToHtml(label)
                            :
                            label
                    }
                </span>
            </label>
        </div>
    )
};

export default Radio

Radio.propTypes = {
  checked: bool.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  handleChange: func.isRequired,
};
