import { useEffect, useState } from "react";
import Select from "react-select";
import { string, func } from "prop-types";

import { isNotEmpty } from "../../utils/Helper";
import store from "../../utils/Store";
import OKButton from "../OKButton";
import { showLoader } from "../../actions/LoaderAction";
import MenuList from "../common/MenuList";
import { getMasterData } from "../../services/MasterDataService";
import { masterDataKeys } from "../../utils/Constant";
import OtherOptionTextInput from "./OtherOptionTextInput/OtherOptionTextInput";

const Dropdown = ({
  masterDataKey,
  handleSubmit,
  placeHolder,
  dropdownClassName,
}) => {
  const [dropdownList, setDropdownList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [submitAnswer, setSubmitAnswer] = useState([]);
  const [textInputValue, setTextInputValue] = useState("");

  const { PINCODE, EDUCATION } = masterDataKeys;

  useEffect(() => {
    store.dispatch(showLoader(true));
    getMasterData(false, [masterDataKey], (data, error) => {
      if (error === null) {
        let dropdownData = [];
        if (masterDataKey === PINCODE) {
          dropdownData = Object.keys(data.pincode);
        } else if (masterDataKey === EDUCATION) {
          dropdownData = Object.values(data.education);
        }
        let optionsObject = dropdownData.map((option) => {
          return { label: option, value: option };
        });
        setDropdownList(optionsObject);
        store.dispatch(showLoader(false));
      }
    });
  }, [masterDataKey]);

  const onChangeSelect = (dropDownSelect) => {
    if (isNotEmpty(dropDownSelect)) {
      setSelectedOption(dropDownSelect);
      setSubmitAnswer([dropDownSelect.value]);
    } else {
      setSelectedOption(null);
      setSubmitAnswer([]);
    }
  };

  const handleTextInputChange = (inputValue) => {
    setTextInputValue(inputValue);
    setSubmitAnswer([textInputValue]);
  };

  const handleOkButton = () => {
    // Submit Text Input Value as Answer
    if (textInputValue.length > 0) {
      let isOtherLocation = true;
      handleSubmit([textInputValue], isOtherLocation);
    } else {
      // Submit Dropdown Option Answer
      handleSubmit(submitAnswer);
    }
    setSubmitAnswer([]);
  };

  const isDisabled = () => {
    let disabledFlag = true;
    if (isNotEmpty(selectedOption)) {
      disabledFlag =
        selectedOption.value === "Others" &&
        textInputValue.replace(/ /g, "").length === 0
          ? true
          : false;
    } else {
      disabledFlag = true;
    }
    return disabledFlag;
  };

  return (
    <>
      <Select
        components={{ MenuList }}
        placeholder={placeHolder}
        value={selectedOption}
        onChange={onChangeSelect}
        options={dropdownList}
        isSearchable={true}
        isClearable={true}
        name={dropdownClassName}
        styles={{
          menuList: (defaultStyles) => ({
            ...defaultStyles,
            "::-webkit-scrollbar": { width: "3px" },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#B1B1B1",
              outline: "#E3E3E3",
            },
          }),
        }}
      />
      <br />
      {selectedOption?.value === "Others" && masterDataKey === PINCODE && (
        <OtherOptionTextInput
          inputValue={textInputValue}
          handleChange={handleTextInputChange}
        />
      )}
      <br />
      <OKButton disabled={isDisabled()} onClick={handleOkButton} />
    </>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  masterDataKey: string.isRequired,
  handleSubmit: func.isRequired,
  placeHolder: string.isRequired,
  dropdownClassName: string.isRequired,
};
