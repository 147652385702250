import React from 'react';
import fbIcon from "../../assets/images/fb-icon.png";
import linkedInIcon from "../../assets/images/linkedin.png";

const ThankyouSection = ()=>(
    [

        <div className="row ml-1">
            <a href="https://www.linkedin.com/company/litmusblox/"><img alt="linkedin" className="pr-2" src={linkedInIcon} /></a>
            <a href="https://facebook.com/litmusblox"><img alt="facebook" className="pr-2" src={fbIcon} /></a>
        </div>
    ]
);

export default ThankyouSection;