import {apiUrls} from "./ApiUrls";
import store from '../utils/Store';
import {postAjaxCall, putAjaxCall} from "./AjaxService";
import {showLoader} from "../actions/LoaderAction";
import {urls} from "../utils/routes";
import setCandidateInterest, {setCandidateInterestDate} from "../actions/InterestAction";

export const submitInterest = () => {
    const { uuid, declineReason, candidateInterest, linkType } = store.getState();
    let apiUrl = `${apiUrls.candidateInterest}?uuid=${uuid}&interest=${candidateInterest}&type=${linkType}`;
    if(candidateInterest) {
        putAjaxCall(apiUrl, function (data, error) {
            if (error === null) {
                store.dispatch(setCandidateInterest(true));
                store.dispatch(setCandidateInterestDate(new Date().getMilliseconds()));
                store.dispatch(showLoader(false));
                document.location = `#${urls.START_CHATBOT_CONSENT}`;
            }
        })
    }
    else{
        apiUrl = `${apiUrl}&candidateNotInterestReasonId=${declineReason}`;
        putAjaxCall(apiUrl, function (data, error) {
            if (error === null) {
                store.dispatch(setCandidateInterest(false));
                store.dispatch(setCandidateInterestDate(new Date().getMilliseconds()));
                store.dispatch(showLoader(false));
                document.location = `#${urls.DECLINE_THANKYOU}`;
            }
        })
    }
};

export const uChatbotSubmitInterest = () => {
  const { uuid, candidateInterest, linkType, declineReason } = store.getState();

  let apiUrl = `${apiUrls.uChatbotCandidateInterest}?uuid=${uuid}&interest=${candidateInterest}&type=${linkType}`;
  if (candidateInterest) {
    postAjaxCall(apiUrl, null, (data, error) => {
      if (error === null) {
        store.dispatch(setCandidateInterestDate(new Date().getMilliseconds()));
        document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
      }
    });
  } else {
    apiUrl = `${apiUrl}&candidateNotInterestReasonId=${declineReason}`;
    postAjaxCall(apiUrl, null, (data, error) => {
      if (error === null) {
        store.dispatch(setCandidateInterestDate(new Date().getMilliseconds()));
        document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
      }
    });
  }
};