import thumbsUp from "../../assets/images/thanks-img.png";
import fbIcon from "../../assets/images/fb-icon.png";
import linkedInIcon from "../../assets/images/linkedin.png";
import PoweredByLogo from "./PoweredByLogo";

const ThankYou = ({ successText, goodLuckText, heading }) => {
  return (
    <div style={{ paddingTop: "200px" }} className={"chatbot-container"}>
      {heading}
      {successText}
      {goodLuckText}

      <div
        style={{ marginTop: "27px", display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: "flex" }}>
          <span
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/litmusblox",
                "_blank"
              )
            }
          >
            <img src={linkedInIcon} alt="linked In icon" />
          </span>

          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/litmusblox/", "_blank")
            }
          >
            <img src={fbIcon} alt="fb Icon" />
          </span>
        </div>
      </div>

      <div
        style={{
          marginTop: "45px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <img style={{ margin: "0 -22px -22px 0" }} src={thumbsUp} alt="" />
      </div>
      <PoweredByLogo />
    </div>
  );
};

export default ThankYou;
