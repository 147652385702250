import React from 'react';
import OKButton from "../OKButton";
import {useSelector} from "react-redux";
import PoweredByLogo from "../common/PoweredByLogo";

const EncourageScreen = ({setShowIntermediateScreen}) => {
    const {jobCandidateMapping} = useSelector(state=>state);
    const candidateName = jobCandidateMapping?.displayName;
    return (
        <div style={
            {
                height: '90vh',
                position: 'fixed',
                width: 'inherit',
                zIndex: 4,
                background: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0px 18px 18px 0px'

            }
        }
        >

            <h4 className={'q-text'} >{`Hey ${candidateName}, good to see you are still here. We have just a few more questions remaining`}</h4>
           <br/>
            <OKButton style={{paddingLeft:'10px'}} btnText={"Let's Keep going!"} disabled={false} onClick={()=>setShowIntermediateScreen(false)}/>
        <PoweredByLogo/>
        </div>
    )
};

export default EncourageScreen;