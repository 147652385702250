import { useSelector } from "react-redux";
import { isNotEmpty } from "../../../utils/Helper";
import { useEffect } from "react";
import { urls } from "../../../utils/routes";
import ThankYou from "../../common/ThankYou";

const ThankYouWrapper = () => {
  const {
    jobCandidateMapping,
    jobDetail,
    isResumeUploaded,
    candidateInterest,
    candidateInterestDate,
  } = useSelector((state) => state);
  const candidateName = isNotEmpty(jobCandidateMapping)
    ? jobCandidateMapping.displayName
    : "";
  const companyName =
    isNotEmpty(jobDetail) && isNotEmpty(jobDetail.companyId)
      ? jobDetail.companyId.companyName
      : "";

  useEffect(() => {
    if (
      !(jobCandidateMapping.chatbotStatus === "Complete") &&
      candidateInterest
    ) {
      document.location = `#${urls.CHATBOT}`;
    } else if (
      !(jobCandidateMapping.chatbotStatus === "Complete") &&
      !candidateInterest &&
      !isNotEmpty(candidateInterestDate)
    ) {
      document.location = `#${urls.CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.DECLINE_THANKYOU}`;
    }
  }, []);

  const heading = (
    <div className="question" style={{ marginBottom: "8px" }}>
      {isResumeUploaded ? "Hooray " : "No problem, "}
      <span style={{ textTransform: "capitalize" }}>{candidateName + "!"}</span>
    </div>
  );

  const successText = (
    <p className="resume-page-text">
      {isResumeUploaded
        ? "Your resume has been successfully uploaded. Good Luck!"
        : "Your application has already been submitted. Good Luck!"}
    </p>
  );

  const goodLuckText = (
    <p style={{ color: "#464646" }}>
      {`If your profile is a match for the job, the ${companyName} team will be
        in touch with you.`}
    </p>
  );

  return (
    <ThankYou
      heading={heading}
      companyName={companyName}
      successText={successText}
      goodLuckText={goodLuckText}
    />
  );
};

export default ThankYouWrapper;
