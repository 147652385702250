export const SET_UCHATBOT_PHASE = "SET_UCHATBOT_PHASE";
export const SET_UCHATBOT_DETAILS = "SET_UCHATBOT_DETAILS";
export const SET_UCHATBOT_QUESTIONS = "SET_UCHATBOT_QUESTIONS";
export const SET_UCHATBOT_ANSWERS = "SET_UCHATBOT_ANSWERS";
export const SET_SCREENINGQS_ANSWERS = "SET_SCREENINGQS_ANSWERS";
export const SET_TEMPLATEQS_ANSWERS = "SET_TEMPLATEQS_ANSWERS";
export const SET_IS_UNIVERSAL_CHATBOT = "SET_IS_UNIVERSAL_CHATBOT";
export const SET_UCHATBOT_FLAGS = "SET_UCHATBOT_FLAGS";

export const setUChatbotPhase = (uChatbotPhase) => {
  return {
    type: SET_UCHATBOT_PHASE,
    uChatbotPhase,
  };
};

export const setUChatbotDetails = (uChatbotDetails) => {
  return {
    type: SET_UCHATBOT_DETAILS,
    uChatbotDetails,
  };
};

export const setUChatbotQuestions = (uChatbotQuestions) => {
  return {
    type: SET_UCHATBOT_QUESTIONS,
    uChatbotQuestions,
  };
};

export const setUChatbotAnswers = (uChatbotAnswers) => {
  return {
    type: SET_UCHATBOT_ANSWERS,
    uChatbotAnswers,
  };
};

export const setIsUniversalChatbot = (isUniversalChatbot) => {
  return {
    type: SET_IS_UNIVERSAL_CHATBOT,
    isUniversalChatbot,
  };
};

export const setUChatbotFlags = (uChatbotFlags) => {
  return {
    type: SET_UCHATBOT_FLAGS,
    uChatbotFlags,
  };
};
