export const SET_ANSWER = "SET_CANDIDATE_ANSWERS";
export const SET_QUICK_ANSWER = "SET_QUICK_ANSWER";
export const SET_ITEM = "SET_ITEM";
export const SET_QUESTION_ID = "SET_QUESTION_ID";
export const SET_QUESTION = "SET_QUESTION";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_MULTILEVEL_OPTIONS = "SET_MULTILEVEL_OPTIONS";
export const SET_QUESTION_TYPE = "SET_QUESTION_TYPE";
export const SET_LINK_TYPE = "SET_LINK_TYPE";


export const setAnswer = (answer) => {
    return {
        type: SET_ANSWER,
        answer
    }
};

export const setQuickAnswer = (answer) => {
    return {
        type: SET_QUICK_ANSWER,
        answer
    }
};

export const setItem = (item) => {
    return {
        type: SET_ITEM,
        item
    }
};

export const setQuestionId = (questionId) => {
    return {
        type: SET_QUESTION_ID,
        questionId
    }
};

export const setQuestion = (question) => {
    return {
        type: SET_QUESTION,
        question
    }
};

export const setOptions = (options) => {
    return {
        type: SET_OPTIONS,
        options
    }
};

export const setMultiLevelOptions = (multiLevelOptions) => {
    return {
        type: SET_MULTILEVEL_OPTIONS,
        multiLevelOptions
    }
};

export const setQuestionType = (questionType) => {
  return {
      type: SET_QUESTION_TYPE,
      questionType
  }
};

export const setLinkType = (linkType) => {
    return {
        type: SET_LINK_TYPE,
        linkType
    }
  };