import { bool, func, string } from 'prop-types';
import React from 'react'
import checkmark from '../assets/images/checkmark.svg'
import {isNotEmpty} from "../utils/Helper";

const OKButton = ({disabled, btnText, onClick}) => {
    return (
        <div>
            {
                disabled ?
                    <React.Fragment>
                        <input  type="button" name={btnText || "OK"} className="ok-button-disabled" value={btnText || "OK"} />
                        {!isNotEmpty(btnText) &&<img alt='Ok Button' style={{ marginLeft:'-32px', marginBottom: '2px',width: '12px', height: '9px',filter: "invert(3%) sepia(3%) saturate(5382%) hue-rotate(177deg) brightness(95%) contrast(89%)"}} src={checkmark}/>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <input  type="button" name={ btnText || "OK" } className="ok-button" value={ btnText || "OK" } onClick={onClick}/>
                        {!isNotEmpty(btnText) && <img alt='Ok Button' style={{ marginLeft:'-32px', marginBottom: '2px',width: '12px', height: '9px',}}  src={checkmark}/>}
                    </React.Fragment>
            }
        </div>
    )
};
export default OKButton

OKButton.propTypes = {
  disabled: bool,
  btnText: string,
  onClick: func.isRequired,
};
