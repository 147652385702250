import { combineReducers } from 'redux';
import uuid from './ApiReducer/Uuid';
import {
    chatbotContent,
    createdBy,
    hrQuestions,
    jcmCommunicationDetails,
    jobCandidateMapping,
    jobDetail,
    chatbotErrorMessage,
} from "./ApiReducer/ChatbotDetail";
import {
    uChatbotPhase,
    uChatbotDetails,
    uChatbotQuestions,
    uChatbotAnswers,
    isUniversalChatbot,
    uChatbotFlags,
} from "./ApiReducer/UChatbotReducer.js";
import { interviewConfirmation, interviewDetail } from "./ApiReducer/InterviewDetail";
import {
    answer,
    item,
    multiLevelOptions,
    options,
    question,
    questionId,
    questionType,
    quickAnswer,
    linkType
} from "./ChatbotReducers";
import {
    candidateInterest,
    candidateInterestDate,
    notInterestedReasons,
    notInterestedReasonsUniversal
} from "./InterestReducer";
import showLoader from "./LoaderReducer";
import { declineReason } from "./DeclineReasonReducer";
import isResumeUploaded from "./ResumeReducer";

const allReducers = combineReducers({
    showLoader,
    jobDetail,
    jobCandidateMapping,
    createdBy,
    jcmCommunicationDetails,
    hrQuestions,
    uChatbotDetails,
    uChatbotQuestions,
    uChatbotAnswers,
    uChatbotPhase,
    isUniversalChatbot,
    uChatbotFlags,
    chatbotContent,
    interviewDetail,
    interviewConfirmation,
    uuid,
    quickAnswer,
    answer,
    questionId,
    question,
    item,
    options,
    multiLevelOptions,
    questionType,
    candidateInterest,
    candidateInterestDate,
    notInterestedReasons,
    notInterestedReasonsUniversal,
    declineReason,
    isResumeUploaded,
    linkType,
    chatbotErrorMessage,
});

export default allReducers;