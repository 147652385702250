export const uuidMatcher = /(?:[a-zA-Z0-9]{8})-(?:[a-zA-Z0-9]{4})-(?:[a-zA-Z0-9]{4})-(?:[a-zA-Z0-9]{4})-(?:[a-zA-Z0-9-]{12})$/g;

//Supported file types for Resume upload
export const supportedFileTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf"
];

export const renderAnswerTypes = {
    RADIO_BUTTON: "Radio button",
    CHECK_BOX: "Checkbox",
    INPUT_BOX: "InputBox",
    CALENDAR: "Calendar",
    LOCATION: "Location",
    SLIDER: "Slider",
    FUTURE_CALENDAR: "FutureCalendar",
    PAST_CALENDAR: "PastCalendar",
    FILTER_DROPDOWN: "MasterData", 
};

export const masterDataKeys = {
  COUNTRIES: "countries",
  QUESTION_TYPE: "questionType",
  EXP_RANGE: "experienceRange",
  ADDRESS_TYPE: "addressType",
  STAGE: "stage",
  PROCESS: "process",
  OLD_FUNCTION: "oldFunction",
  EXPERTISE: "expertise",
  EDUCATION: "education",
  INDUSTRY: "industry",
  KEY_SKILLS: "keySkills",
  NOTICE_PERIOD: "noticePeriod",
  SCREENING_QUESTIONS: "screeningQuestions",
  CONFIG_SETTINGS: "configSettings",
  SUPPORTED_FILE_FORMATS: "supportedFileFormats",
  SUPPORTED_CV_FILE_FORMATS: "supportedCvFileFormats",
  ADD_JOB_PAGES: "addJobPages",
  CURRENCY_LIST: "currencyList",
  USER_ROLE: "userRole",
  REASON_FOR_CHANGE: "reasonForChange",
  DEFAULT_EXPORT_FORMATS: "defaultExportFormats",
  CALL_OUTCOME: "callOutCome",
  REFERRER_RELATION: "referrerRelation",
  JOB_TYPE: "jobType",
  STAGE_STEP_MASTER_MAP: "stageStepMasterMap",
  INTERVIEW_TYPE: "interviewType",
  INTERVIEW_MODE: "interviewMode",
  CANCELLATION_REASONS: "cancellationReasons",
  NO_SHOW_REASONS: "noShowReasons",
  INTERVIEW_CONFIRMATION: "interviewConfirmation",
  JOB_INDUSTRY: "jobIndustry",
  FUNCTION: "function",
  ROLE: "role",
  SALARY_RANGE: "salaryRange",
  HM_REJECT_REASON: "hiringManagerRejectReasonMap",
  ARCHIVE_STATUS: "archiveStatus",
  ARCHIVE_REASON: "archiveReason",
  ATTRIBUTE_MAP: "attributeMap",
  STATEMENT_BLOCKS: "statementBlocks",
  SCORECARD_STATEMENT_BLOCKS: "scoreCardStatementBlocks",
  CANDIDATE_REJECTION_REASON: "candidateRejectionReasonMap",
  CANDIDATE_NOT_INTERESTED_REASON: "candidateNotInterestedReason",
  CANDIDATE_NOT_INTERESTED_REASON_UNIVERSAL: "campaignNotInterestedReason",
  LOCATION: "location",
  CREDIT_TRANSACTION_TYPE: "creditTransactionType",
  CANDIDATE_DOCS_TYPE: "candidateDocumentsType",
  OTP_EXPIRY_MINS: "otpExpiryMinutes",
  COMPANY_SOURCE: "companySourceMap",
  FOLLOWUP_TYPE: "followupType",
  FOLLOWUP_STATUS: "followupStatus",
  INTERVIEW_LEVELS: "interviewLevelsMap",
  OVERALL_RATING: "overallRating",
  FUNCTIONAL_SKILLS: "functionalSkills",
  NEW_SKILLS: "newSkills",
  PINCODE: "pincode",
  CITY_NAMES: "cityNames",
  CANDIDATE_SOURCES: "candidateSources",
  CANDIDATE_SOURCE_CATEGORIES: "candidateSourceCategories",
  TEMPLATE_EXP_RANGE: "templateExperienceRange",
};