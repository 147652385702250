import { isNotEmpty } from "../../utils/Helper";
import { useSelector, useDispatch } from "react-redux";

import PoweredByLogo from "../common/PoweredByLogo";
import OKButton from "../OKButton";
import Radio from "../answerTypes/RadioButton/index";

import { setDeclineReason } from "../../actions/DeclineReasonActions";
import { uChatbotSubmitInterest } from "../../services/InterestService";
import { useEffect } from "react";
import { urls } from "../../utils/routes";

const UniversalChatbotDeclineReason = () => {
  const {
    declineReason,
    notInterestedReasons,
    candidateInterest,
    uChatbotPhase,
    candidateInterestDate,
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidateInterest && uChatbotPhase !== "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    } else if (candidateInterest && uChatbotPhase === "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    }
  }, [candidateInterest, candidateInterestDate, uChatbotPhase]);

  const declineReasonText =
    "Can you help me with the reason for declining this opportunity? Select the most appropriate one";

  return (
    <div className={"chatbot-container  Hr-selection-screen bottom-container"}>
      <h4>Your reason for decline?</h4>

      <p>{declineReasonText}</p>

      <p className={"question-breadcrumb mb-0"}>Select any one option</p>
      {isNotEmpty(notInterestedReasons) &&
        Object.keys(notInterestedReasons).map((reasonId, index) => (
          <Radio
            key={`${reasonId}_${index}`}
            value={reasonId}
            checked={reasonId === declineReason}
            handleChange={() => dispatch(setDeclineReason(reasonId))}
            label={notInterestedReasons[reasonId]}
          />
        ))}
      <br />
      <OKButton btnText={"Submit"} onClick={() => uChatbotSubmitInterest()} />
      <PoweredByLogo />
    </div>
  );
};

export default UniversalChatbotDeclineReason;
