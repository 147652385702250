import React from 'react';
import OKButton from "../OKButton";
import {useSelector} from "react-redux";

const StartQuickScreening = ({skillsAndAttributes, setShowChatbot}) => {
    const {jobCandidateMapping} = useSelector(state=>state);
    const candidateName = jobCandidateMapping?.displayName;
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
            <p className={'mb-0'} style={{fontSize: '14px', color: '#464646'}}><i>{`${candidateName}, this section is to understand your skills & competencies`}</i></p>
            <h4 className={"q-text"}>Tell us about your expertise level on each of the following</h4>
            {
                skillsAndAttributes.map((skill, index)=>(
                    <div key={`${skill}_${index}`} className={'skills-style'}>{skill}</div>
                ))
            }
            <OKButton onClick={()=>setShowChatbot(true)} disabled={false} btnText={"Okay. Let's start."}/>
        </div>
    )
};

export default StartQuickScreening;