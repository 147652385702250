import React, {useEffect} from 'react';
import {isNotEmpty} from "../../utils/Helper";
import {getValue} from "../../services/CookieService";
import {uuidMatcher} from "../../utils/Constant";
import ThankyouSection from "./ThankyouSection";
import {getInterviewData} from "../../services/DataService";
import {useSelector} from "react-redux";
import PoweredByLogo from "../common/PoweredByLogo";

const Dropout = ()=> {
    const interviewDetail = useSelector(state=>state.interviewDetail);

    useEffect(()=>{
        if(isNotEmpty(interviewDetail.candidateConfirmationTime)){
            document.location = '#/interview/alreadyconfirmed'
        }
    }, []);

    if (!isNotEmpty(interviewDetail)) {
        if (!isNotEmpty(getValue('interviewUuid')) && uuidMatcher.test(getValue('interviewUuid'))) {
            getInterviewData(getValue('interviewUuid'));
        }
    }
    return (
        isNotEmpty(interviewDetail) &&
        <div className="chatbot-container">
            <p className="mb-4 mt-3" style={{fontSize:"21px",color:"#000",lineHeight:"1.2em"}}>
                What went wrong, <br/><span style={{textTransform:"capitalize"}}><b>{interviewDetail.displayName}?</b></span>
            </p>
            <p>Sorry to hear that you are not able to make it for the interview.
                We’ll be in touch with you for other opportunities with {interviewDetail.job.companyId.companyName}</p>


                <ThankyouSection />

                <PoweredByLogo/>
        </div>
    )
};

export default Dropout;