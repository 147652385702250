import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showLoader } from "../../actions/LoaderAction";
import {
  setUChatbotAnswers,
  setUChatbotFlags,
} from "../../actions/UChatbotDataActions";
import { postAjaxCall } from "../../services/AjaxService";
import { apiUrls } from "../../services/ApiUrls";
import { getUniversalChatbotData } from "../../services/DataService";
import { getMasterData } from "../../services/MasterDataService";
import { masterDataKeys, renderAnswerTypes } from "../../utils/Constant";

import { deepClone, isNotEmpty } from "../../utils/Helper";
import { urls } from "../../utils/routes";
import {
  UChatbotQs,
  levels,
  uChatbotPhases,
} from "../../utils/UChatbotConstants";
import CommonTransition from "../common/CommonTransition";

import PoweredByLogo from "../common/PoweredByLogo";
import Answer from "./QuestionAnswerWrapper/Answer";
import Question from "./QuestionAnswerWrapper/Question";
import UChatbotStartHrSection from "./UChatbotStartHrSection";

const UniversalChatbotSections = () => {
  const {
    uChatbotQuestions,
    uuid,
    uChatbotPhase,
    linkType,
    candidateInterest,
    candidateInterestDate,
    uChatbotDetails,
    uChatbotFlags,
    uChatbotAnswers,
  } = useSelector((state) => state);
  const { templateQuestions, defaultQuestions, screeningQuestions } =
    uChatbotQuestions;
  const { questionToAsk } = uChatbotDetails;
  const dispatch = useDispatch();

  const [templateQuestionsList, setTemplateQuestionsList] = useState([]);
  const [templateQuestionOptionsObject, setTemplateQuestionOptionsObject] =
    useState({});
  const [templateQuestionLevel, setTemplateQuestionLevel] = useState();
  const [currentQuestionObj, setCurrentQuestionObj] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionOptionsList, setCurrentQuestionOptionsList] = useState(
    []
  );
  const [currentQuestionOptionsIdList, setCurrentQuestionOptionsIdList] =
    useState([]);

  const [selectedL1Options, setSelectedL1Options] = useState([]);
  const [templateQsAnswersList, setTemplateQsAnswersList] = useState([]);
  const [templateOptionsIdObj, setTemplateOptionsIdObj] = useState({});
  const [templateAllQsOptionsIdObj, setTemplateAllQsOptionsIdObj] = useState(
    {}
  );
  const [l2QuestionIndex, setL2QuestionIndex] = useState(0);
  const [experienceOptions, setExperienceOptions] = useState([]);

  const [defaultQuestionsList, setDefaultQuestionsList] = useState([]);
  const [defaultQsAnswersList, setDefaultQsAnswersList] = useState([]);

  const [hrQuestionsList, setHrQuestionsList] = useState([]);
  const [hrQsAnswersObj, setHrQsAnswersObj] = useState([]);
  const [startHrSectionFlag, setStartHrSectionFlag] = useState(false);

  const [customQuestionsList, setCustomQuestionsList] = useState([]);
  const [customQsAnswersObj, setCustomQsAnswersObj] = useState([]);

  const firstRender = useRef(true);

  // Redirects to correct route based on chatbot status and candidate interest.
  useEffect(() => {
    if (uChatbotPhase === uChatbotPhases.COMPLETE) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    } else if (!candidateInterest && !isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    }

    // disables back button once chatbot qs are first rendered
    if (firstRender.current) {
      firstRender.current = false;
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }
  }, [uChatbotPhase, candidateInterest, candidateInterestDate]);

  // Initializes different section questions and stores in state
  useEffect(() => {
    if (
      templateQuestionsList.length === 0 &&
      (uChatbotPhase === uChatbotPhases.INVITED ||
        uChatbotPhase === uChatbotPhases.TEMPLATE)
    ) {
      initExperienceRangeQs();
      initializeTemplateQuestions();
    } else if (
      defaultQuestionsList.length === 0 &&
      uChatbotPhase === uChatbotPhases.DEFAULT &&
      uChatbotFlags.defaultQsCompletedFlag === false
    ) {
      initExperienceRangeQs();
      dispatch(
        setUChatbotFlags({
          ...uChatbotFlags,
          templateQsCompletedFlag: true,
        })
      );
      initializeDefaultQs();
    } else if (
      hrQuestionsList.length === 0 &&
      uChatbotPhase === uChatbotPhases.SCREENING &&
      uChatbotFlags.hrQsCompletedFlag === false
    ) {
      dispatch(
        setUChatbotFlags({
          ...uChatbotFlags,
          templateQsCompletedFlag: true,
          defaultQsCompletedFlag: true,
        })
      );
      initializeHrQuestions();
    }
  }, [defaultQuestions, screeningQuestions]);

  useEffect(() => {
    questionToRender();
  }, [
    templateQuestionsList,
    currentQuestionIndex,
    templateQuestionOptionsObject,
    defaultQuestionsList,
    hrQuestionsList,
    customQuestionsList,
  ]);

  useEffect(() => {
    getQuestionAnswerJSX();
  }, [currentQuestionOptionsList, currentQuestionObj]);

  // Handles question responses API calls and completed sections flag checks
  useEffect(() => {
    // api call after each level of template qs is complete
    if (
      uChatbotFlags.templateQsCompletedFlag === false &&
      isNotEmpty(templateQuestionsList[currentQuestionIndex]?.level) &&
      (templateQuestionLevel !==
        templateQuestionsList[currentQuestionIndex]?.level ||
        (templateQuestionsList[currentQuestionIndex]?.level === levels.LEVEL1 &&
          selectedL1Options.length === 0))
    ) {
      submitTemplateQsAnswers();
      setTemplateQuestionLevel(
        templateQuestionsList[currentQuestionIndex]?.level
      );
    } else if (
      currentQuestionIndex === templateQuestionsList.length &&
      templateQuestionsList.length !== 0 &&
      uChatbotFlags.templateQsCompletedFlag === false
    ) {
      submitTemplateQsAnswers(true);
      dispatch(
        setUChatbotFlags({ ...uChatbotFlags, templateQsCompletedFlag: true })
      );
    } else if (
      uChatbotFlags.templateQsCompletedFlag === true &&
      uChatbotFlags.defaultQsCompletedFlag === false
    ) {
      if (
        uChatbotFlags.templateQsCompletedFlag === true &&
        uChatbotFlags.defaultQsCompletedFlag === false &&
        isExperienceRangeQs(defaultQuestionsList[currentQuestionIndex - 1]) &&
        !isExperienceRangeQs(defaultQuestionsList[currentQuestionIndex])
      ) {
        // Submit default qs answer after each skills qs along with all experiences is complete
        submitDefaultQsAnswers();
      } else if (
        currentQuestionIndex === defaultQuestionsList.length &&
        defaultQuestionsList.length !== 0 &&
        uChatbotFlags.defaultQsCompletedFlag === false
      ) {
        dispatch(
          setUChatbotFlags({ ...uChatbotFlags, defaultQsCompletedFlag: true })
        );
        submitDefaultQsAnswers(true);
      }
    } else if (
      currentQuestionIndex === hrQuestionsList.length &&
      hrQuestionsList.length !== 0 &&
      uChatbotFlags.hrQsCompletedFlag === false
    ) {
      dispatch(setUChatbotFlags({ ...uChatbotFlags, hrQsCompletedFlag: true }));
      initializeCustomQuestions();
    } else if (
      currentQuestionIndex === customQuestionsList.length &&
      customQuestionsList.length !== 0 &&
      uChatbotFlags.customQsCompletedFlag === false
    ) {
      dispatch(
        setUChatbotFlags({ ...uChatbotFlags, customQsCompletedFlag: true })
      );
    }
  }, [
    templateQsAnswersList,
    defaultQsAnswersList,
    hrQsAnswersObj,
    customQsAnswersObj,
  ]);

  const initExperienceRangeQs = () => {
    const { TEMPLATE_EXP_RANGE } = masterDataKeys;
    getMasterData(false, [TEMPLATE_EXP_RANGE], (data, error) => {
      if (error === null) {
        let formattedTemplateExperienceRange = data.templateExperienceRange.map(
          (expRange) => {
            return expRange === "01" ? `${expRange} Year` : `${expRange} Years`;
          }
        );
        setExperienceOptions(formattedTemplateExperienceRange);
      }
    });
  };

  const isExperienceRangeQs = (questionObj) => {
    return questionObj
      ? questionObj?.question === UChatbotQs.EXPERIENCE_RANGE_QS
      : currentQuestionObj?.question === UChatbotQs.EXPERIENCE_RANGE_QS;
  };

  const submitTemplateQsAnswers = (templateQsCompletedFlag) => {
    let reqData = [];
    let templateQsAnswersLength = templateQsAnswersList.length;
    if (templateQsAnswersLength > 0) {
      let answerObj = templateQsAnswersList[templateQsAnswersLength - 1];
      let tempObj = {};
      tempObj["level"] = templateOptionsIdObj[Object.keys(answerObj)[0]].level;
      tempObj["responses"] = {};
      for (const key in answerObj) {
        tempObj.responses[templateOptionsIdObj[key].id] = {
          [key]: answerObj[key],
        };
      }
      reqData.push(tempObj);
    }

    let api = `${apiUrls.saveTemplateQsResponse}?uuid=${uuid}`;
    dispatch(showLoader(true));
    postAjaxCall(api, reqData, (data, error) => {
      if (error === null) {
        dispatch(showLoader(false));
        if (templateQsCompletedFlag) {
          getUniversalChatbotData();
        }
      }
    });
  };

  const submitDefaultQsAnswers = (defaultQsCompletedFlag) => {
    let reqData = [];
    const { defaultAnswers } = uChatbotAnswers;
    const defaultQuestionsAnsweredLength = Object.keys(defaultAnswers).length;
    // id depends on if there are any previously answered qs
    const defaultAnswerId =
      defaultQuestionsAnsweredLength > 0
        ? defaultQuestionsAnsweredLength + 1
        : defaultQsAnswersList.length;
    if (defaultQsAnswersList.length > 0) {
      let answerObj = {};
      answerObj["id"] = defaultAnswerId;
      answerObj["responses"] =
        defaultQsAnswersList[defaultQsAnswersList.length - 1];
      reqData.push(answerObj);
    }

    let api = `${apiUrls.saveDefaultQsResponse}?uuid=${uuid}`;
    dispatch(showLoader(true));
    postAjaxCall(api, reqData, (data, error) => {
      if (error === null) {
        dispatch(showLoader(false));
        if (defaultQsCompletedFlag) {
          getUniversalChatbotData();
        }
      }
    });
  };

  const submitScreeningQsAnswer = (qIdAnswer, qId, isOtherLocation) => {
    let reqData = {};
    isOtherLocation
      ? (reqData = {
          deepScreeningQuestionResponseMap: qIdAnswer,
          quickScreeningQuestionResponseMap: {},
          otherLocation: qIdAnswer[qId][0],
          type: linkType,
        })
      : (reqData = {
          deepScreeningQuestionResponseMap: qIdAnswer,
          quickScreeningQuestionResponseMap: {},
          otherLocation: "",
          type: linkType,
        });

    let api = `${apiUrls.saveScreeningQsResponse}?uuid=${uuid}`;
    dispatch(showLoader(true));
    postAjaxCall(api, reqData, (data, error) => {
      if (error === null) {
        dispatch(
          setUChatbotAnswers({
            ...uChatbotAnswers,
            screeningAnswers: {
              ...uChatbotAnswers.screeningAnswers,
              ...{ [qId]: qIdAnswer[qId][0] },
            },
          })
        );
        dispatch(showLoader(false));
        if (
          currentQuestionIndex === hrQuestionsList.length - 1 ||
          currentQuestionIndex === customQuestionsList.length - 1
        ) {
          getUniversalChatbotData();
        }
      }
    });
  };

  const initializeHrQuestions = () => {
    let tempHrQuestionsList = [];
    let unansweredHrQuestionsList = [];
    setCurrentQuestionIndex(0);
    let screeningQsIds = Object.keys(uChatbotAnswers.screeningAnswers);
    // filter out answered screening qs
    unansweredHrQuestionsList =
      screeningQuestions?.masterScreeningQuestions.filter((questionObj) => {
        return !screeningQsIds.includes(questionObj.id.toString());
      });

    if (unansweredHrQuestionsList.length === 0) {
      dispatch(setUChatbotFlags({ ...uChatbotFlags, hrQsCompletedFlag: true }));
      initializeCustomQuestions();
    } else {
      unansweredHrQuestionsList &&
        unansweredHrQuestionsList.forEach((questionObj) => {
          tempHrQuestionsList.push(
            isNotEmpty(questionObj.masterScreeningQuestionId)
              ? {
                  question: questionObj.masterScreeningQuestionId?.question,
                  id: questionObj.id,
                  ansType:
                    questionObj.masterScreeningQuestionId?.questionType?.value,
                  options: questionObj.masterScreeningQuestionId?.options,
                  multiLevelOptions:
                    questionObj.masterScreeningQuestionId?.multiLevelOptions,
                  screeningQuestions: true,
                  customizeQuestion:
                    questionObj.masterScreeningQuestionId?.customizeQuestion,
                }
              : {
                  question: questionObj.companyHrQuestion?.question,
                  id: questionObj.id,
                  ansType: questionObj.companyHrQuestion?.questionType?.value,
                  options: questionObj.companyHrQuestion?.options,
                  multiLevelOptions:
                    questionObj.companyHrQuestion?.multiLevelOptions,
                  screeningQuestions: true,
                  customizeQuestion:
                    questionObj.companyHrQuestion?.customizeQuestion,
                }
          );
        });
      setHrQuestionsList(tempHrQuestionsList);
    }
  };

  const initializeCustomQuestions = () => {
    let tempCustomQuestionsList = [];
    let unansweredCustomQuestionsList = [];
    setCurrentQuestionIndex(0);
    let screeningQsIds = Object.keys(uChatbotAnswers.screeningAnswers);
    // filter out answered screening qs
    unansweredCustomQuestionsList =
      screeningQuestions?.userScreeningQuestions.filter((questionObj) => {
        return !screeningQsIds.includes(questionObj.id.toString());
      });

    if (unansweredCustomQuestionsList.length === 0) {
      dispatch(
        setUChatbotFlags({ ...uChatbotFlags, customQsCompletedFlag: true })
      );
    } else {
      screeningQuestions?.userScreeningQuestions &&
        screeningQuestions.userScreeningQuestions.forEach((questionObj) => {
          tempCustomQuestionsList.push({
            question: questionObj.userScreeningQuestionId?.question,
            id: questionObj.id,
            ansType: questionObj.userScreeningQuestionId?.questionType?.value,
            options: questionObj.userScreeningQuestionId?.options,
            screeningQuestions: true,
          });
        });
      setCustomQuestionsList(tempCustomQuestionsList);
    }
  };

  const initializeDefaultQs = () => {
    let tempQuestionsList = [];
    setCurrentQuestionIndex(0);
    defaultQuestions &&
      defaultQuestions.forEach((questionObj) => {
        tempQuestionsList.push({
          tag: questionObj.tag,
          id: questionObj.id,
          anyN: questionObj.anyN,
          ansType: questionObj.ansType.value,
          options: questionObj.options,
          fullQsText:
            questionObj.tag === UChatbotQs.TOP_SKILLS_TAG
              ? questionToAsk.topSkills
              : questionToAsk.otherSkills,
          anyNText:
            questionObj.tag === UChatbotQs.TOP_SKILLS_TAG
              ? questionToAsk.topSkillsAnyN
              : questionToAsk.commaSeparatedAnswer,
        });
      });

    // Set previously answered uchatbot questions as complete
    let { defaultAnswers } = uChatbotAnswers;
    let defaultQuestionsAnsweredList = Object.keys(defaultAnswers);
    if (defaultQuestionsAnsweredList.length > 0) {
      tempQuestionsList.forEach((questionObj) => {
        defaultQuestionsAnsweredList.forEach((answeredQuestion) => {
          if (answeredQuestion === questionObj.tag) {
            questionObj.completed = true;
          } else {
            questionObj.completed =
              questionObj.completed === true ? true : false;
          }
        });
      });
    }

    setDefaultQuestionsList(tempQuestionsList);
  };

  const initializeTemplateQuestions = () => {
    let tempTemplateQuestionsList = [];
    let tempTemplateOptionsIdObj = {};
    let tempTemplateQuestionOptionsObject = {};
    let tempTemplateAllQsOptionsIdObj = {};
    templateQuestions &&
      templateQuestions.forEach((templateL1Obj) => {
        // L1 Template List
        // templateL1Obj = L1 Question/Options List
        if (
          !isNotEmpty(
            tempTemplateQuestionsList.find(
              (questionObj) => questionObj.question === templateL1Obj.key
            )
          )
        ) {
          tempTemplateQuestionsList.push({
            question: templateL1Obj.key,
            ansType: templateL1Obj.ansType?.value,
            anyN: templateL1Obj.anyN,
            level: levels.LEVEL1,
            anyNText: questionToAsk.anyN,
            fullQsText:
              templateL1Obj.key === "Area"
                ? questionToAsk.template_l1_qs.replace(
                    "$key",
                    "Functional Area"
                  )
                : questionToAsk.template_l1_qs.replace(
                    "$key",
                    templateL1Obj.key
                  ),
          });
        }
        // L1 QuestionOptions List
        // If Question/Option is already present in list, then push option in list
        if (isNotEmpty(tempTemplateQuestionOptionsObject[templateL1Obj.key])) {
          tempTemplateQuestionOptionsObject[templateL1Obj.key].push(
            templateL1Obj.option
          );

          tempTemplateAllQsOptionsIdObj[templateL1Obj.key].push({
            [templateL1Obj.option]: templateL1Obj.id,
          });

          tempTemplateOptionsIdObj = {
            ...tempTemplateOptionsIdObj,
            [templateL1Obj.option]: {
              id: templateL1Obj.id,
              level: levels.LEVEL1,
            },
          };
        } else {
          // Create an empty list for the new qs and push option
          tempTemplateQuestionOptionsObject[templateL1Obj.key] = [];
          tempTemplateQuestionOptionsObject[templateL1Obj.key].push(
            templateL1Obj.option
          );

          tempTemplateAllQsOptionsIdObj[templateL1Obj.key] = [];
          tempTemplateAllQsOptionsIdObj[templateL1Obj.key].push({
            [templateL1Obj.option]: templateL1Obj.id,
          });

          tempTemplateOptionsIdObj = {
            ...tempTemplateOptionsIdObj,
            [templateL1Obj.option]: {
              id: templateL1Obj.id,
              level: levels.LEVEL1,
            },
          };
        }

        // L2 Template List
        if (
          isNotEmpty(
            templateL1Obj.uchatbotTemplateL2List &&
              templateL1Obj.uchatbotTemplateL2List.length > 0
          )
        ) {
          templateL1Obj.uchatbotTemplateL2List.forEach(
            (uchatbotTemplateL2Obj) => {
              // uchatbotTemplateL2Obj = L2 Question/Options List
              if (
                !isNotEmpty(
                  tempTemplateQuestionsList.find(
                    (questionObj) =>
                      questionObj.question === uchatbotTemplateL2Obj.key
                  )
                )
              ) {
                tempTemplateQuestionsList.push({
                  question: uchatbotTemplateL2Obj.key,
                  ansType: uchatbotTemplateL2Obj.ansType?.value,
                  anyN: uchatbotTemplateL2Obj.anyN,
                  level: levels.LEVEL2,
                  anyNText: questionToAsk.anyN,
                  fullQsText: questionToAsk.template_l2_qs.replace(
                    "$key",
                    uchatbotTemplateL2Obj.key
                  ),
                });
              }
              // L2 QuestionOptions List
              if (
                isNotEmpty(
                  tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key]
                )
              ) {
                tempTemplateOptionsIdObj = {
                  ...tempTemplateOptionsIdObj,
                  [uchatbotTemplateL2Obj.option]: {
                    id: uchatbotTemplateL2Obj.id,
                    level: levels.LEVEL2,
                  },
                };

                if (
                  isNotEmpty(
                    tempTemplateQuestionOptionsObject[
                      uchatbotTemplateL2Obj.key
                    ][templateL1Obj.option]
                  )
                ) {
                  tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ].push(uchatbotTemplateL2Obj.option);

                  tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ].push({
                    [uchatbotTemplateL2Obj.option]: uchatbotTemplateL2Obj.id,
                  });
                } else {
                  tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ] = [];
                  tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ].push(uchatbotTemplateL2Obj.option);

                  tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ] = [];
                  tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key][
                    templateL1Obj.option
                  ].push({
                    [uchatbotTemplateL2Obj.option]: uchatbotTemplateL2Obj.id,
                  });
                }
              } else {
                tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key] =
                  {};
                tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key][
                  templateL1Obj.option
                ] = [];
                tempTemplateQuestionOptionsObject[uchatbotTemplateL2Obj.key][
                  templateL1Obj.option
                ].push(uchatbotTemplateL2Obj.option);

                tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key] = {};
                tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key][
                  templateL1Obj.option
                ] = [];
                tempTemplateAllQsOptionsIdObj[uchatbotTemplateL2Obj.key][
                  templateL1Obj.option
                ].push({
                  [uchatbotTemplateL2Obj.option]: uchatbotTemplateL2Obj.id,
                });

                tempTemplateOptionsIdObj = {
                  ...tempTemplateOptionsIdObj,
                  [uchatbotTemplateL2Obj.option]: {
                    id: uchatbotTemplateL2Obj.id,
                    level: levels.LEVEL2,
                  },
                };
              }
            }
          );
        }
      });

    // Set previously answered uchatbot questions as complete
    let { templateAnswers } = uChatbotAnswers;
    let templateQuestionsAnsweredList = Object.keys(templateAnswers);
    if (templateQuestionsAnsweredList.length > 0) {
      tempTemplateQuestionsList.forEach((questionObj) => {
        templateQuestionsAnsweredList.forEach((answeredQuestion) => {
          if (answeredQuestion === questionObj.question) {
            questionObj.completed = true;
            if (questionObj.level === levels.LEVEL1) {
              let selectedL1Options = Object.keys(
                templateAnswers[answeredQuestion]
              );
              // check if the option selected in l1 has options in l2
              let filteredSelectedL1Options = selectedL1Options.filter(
                (answer) => {
                  return tempTemplateQuestionOptionsObject[
                    answeredQuestion
                  ].includes(answer);
                }
              );
              setSelectedL1Options(filteredSelectedL1Options);
              // Insert multiple L2 questions if multiple L1 options selected
              if (filteredSelectedL1Options.length > 1) {
                for (
                  let index = 0;
                  index < filteredSelectedL1Options.length - 1;
                  index++
                ) {
                  tempTemplateQuestionsList.splice(
                    templateQuestionsAnsweredList.length,
                    0,
                    tempTemplateQuestionsList[
                      templateQuestionsAnsweredList.length
                    ]
                  );
                }
              }
            }
          } else {
            questionObj.completed =
              questionObj.completed === true ? true : false;
          }
        });
      });
    }

    setTemplateQuestionsList(tempTemplateQuestionsList);
    setTemplateQuestionOptionsObject(tempTemplateQuestionOptionsObject);
    setTemplateAllQsOptionsIdObj(tempTemplateAllQsOptionsIdObj);
    setTemplateOptionsIdObj(tempTemplateOptionsIdObj);
  };

  const questionToRender = () => {
    setCurrentQuestionOptionsList([]);
    setCurrentQuestionOptionsIdList([]);
    let questionObj;

    if (
      uChatbotFlags.templateQsCompletedFlag === false &&
      (uChatbotPhase === uChatbotPhases.INVITED ||
        uChatbotPhase === uChatbotPhases.TEMPLATE)
    ) {
      if (
        isNotEmpty(templateQuestionsList) &&
        currentQuestionIndex < templateQuestionsList.length
      ) {
        // if qs was previously answered, then skip it
        if (templateQuestionsList[currentQuestionIndex].completed === true) {
          setCurrentQuestionIndex((prevState) => prevState + 1);
        } else {
          // else render options and set questionObj
          questionObj = templateQuestionsList[currentQuestionIndex];
          if (
            !isNotEmpty(templateQuestionLevel) &&
            isNotEmpty(questionObj?.level)
          ) {
            setTemplateQuestionLevel(questionObj?.level);
          }
          optionsToRender(questionObj);
        }
      }
    } else if (
      uChatbotFlags.defaultQsCompletedFlag === false &&
      uChatbotPhase === uChatbotPhases.DEFAULT
    ) {
      if (
        isNotEmpty(defaultQuestionsList) &&
        currentQuestionIndex < defaultQuestionsList.length
      ) {
        // if qs was previously answered, then skip it
        if (defaultQuestionsList[currentQuestionIndex].completed === true) {
          setCurrentQuestionIndex((prevState) => prevState + 1);
        } else {
          // else render options and set questionObj
          questionObj = defaultQuestionsList[currentQuestionIndex];
          if (isExperienceRangeQs(questionObj)) {
            optionsToRender(questionObj);
          }
        }
      }
    } else if (
      uChatbotFlags.hrQsCompletedFlag === false &&
      uChatbotPhase === uChatbotPhases.SCREENING
    ) {
      if (
        isNotEmpty(hrQuestionsList) &&
        currentQuestionIndex < hrQuestionsList.length
      ) {
        questionObj = hrQuestionsList[currentQuestionIndex];
        if (questionObj.options) {
          optionsToRender(questionObj);
        }
      }
    } else if (
      uChatbotFlags.customQsCompletedFlag === false &&
      uChatbotPhase === uChatbotPhases.SCREENING
    ) {
      if (
        isNotEmpty(customQuestionsList) &&
        currentQuestionIndex < customQuestionsList.length
      ) {
        questionObj = customQuestionsList[currentQuestionIndex];
        if (questionObj.options) {
          optionsToRender(questionObj);
        }
      }
    }
    // questionObj is empty when qs is skipped(previously answered)
    if (isNotEmpty(questionObj)) {
      setCurrentQuestionObj(questionObj);
    }
  };

  const optionsToRender = (questionObj) => {
    let optionsList = [];
    let optionsIdList = [];
    if (isExperienceRangeQs(questionObj)) {
      optionsList = experienceOptions;
    } else if (
      uChatbotFlags.templateQsCompletedFlag === false &&
      (uChatbotPhase === uChatbotPhases.INVITED ||
        uChatbotPhase === uChatbotPhases.TEMPLATE)
    ) {
      // If L2 Question
      if (!Array.isArray(templateQuestionOptionsObject[questionObj.question])) {
        // l2QuestionIndex stores index of qs to ask from l1 selected options
        let tempL2QuestionIndex = l2QuestionIndex;
        optionsList =
          templateQuestionOptionsObject[questionObj.question][
            selectedL1Options[tempL2QuestionIndex]
          ];

        optionsIdList =
          templateAllQsOptionsIdObj[questionObj.question][
            selectedL1Options[tempL2QuestionIndex]
          ];
        setL2QuestionIndex((prevState) => prevState + 1);
      }
      // If L1 Question
      else {
        setL2QuestionIndex(0);
        optionsList = templateQuestionOptionsObject[questionObj.question];

        optionsIdList = templateAllQsOptionsIdObj[questionObj.question];
      }
    } else if (
      questionObj.screeningQuestions === true &&
      uChatbotPhase === uChatbotPhases.SCREENING
    ) {
      optionsList = questionObj.options;
    }
    setCurrentQuestionOptionsList(optionsList);
    setCurrentQuestionOptionsIdList(optionsIdList);
  };

  const getQuestionAnswerJSX = () => {
    if (currentQuestionObj) {
      return (
        <CommonTransition key={`${currentQuestionIndex}_qaWrapper`}>
          <Question
            questionObj={currentQuestionObj}
            questionIndex={currentQuestionIndex}
            selectedL1Options={selectedL1Options}
            templateQuestionLevel={templateQuestionLevel}
            l2QuestionIndex={l2QuestionIndex}
          />
          <Answer
            handleSubmit={handleSubmit}
            questionObj={currentQuestionObj}
            optionsList={currentQuestionOptionsList}
          />
        </CommonTransition>
      );
    } else {
      return <div></div>;
    }
  };

  const getCurrentAnswerList = (answer) => {
    let tempAnswerList = [];
    // For All Radio Button Answers and Multilevel qs
    if (Array.isArray(answer)) {
      tempAnswerList = answer;
    }
    // For template and default qs
    else if (!isNotEmpty(currentQuestionObj.screeningQuestions)) {
      if (
        answer.includes(",") &&
        uChatbotFlags.templateQsCompletedFlag === true
      ) {
        // For Input Box Answers
        answer = answer.replace(/ /g, "");
        tempAnswerList = answer.split(",");
      } else if (answer.includes("%$")) {
        // For Check Box Answers
        tempAnswerList = answer.split("%$");
      } else {
        tempAnswerList[0] = answer;
      }
    }
    // If screening qs but not radio button
    else if (!isNotEmpty(currentQuestionObj.multiLevelOptions)) {
      tempAnswerList[0] = answer;
    }
    return tempAnswerList;
  };

  const handleExperienceRangeAnswer = (answerList, currentAnswerList) => {
    for (const answersObj of answerList) {
      for (const key in answersObj) {
        if (
          answersObj.hasOwnProperty(key) &&
          key === currentQuestionObj.field
        ) {
          // Remove "Years" from experience range answer and store the number
          answersObj[key] = currentAnswerList[0].split(" ")[0];
          return;
        }
      }
    }
  };

  const handleSubmit = (answer, isOtherLocation) => {
    const currentAnswerList = getCurrentAnswerList(answer);
    if (
      uChatbotFlags.templateQsCompletedFlag === false &&
      (uChatbotPhase === uChatbotPhases.INVITED ||
        uChatbotPhase === uChatbotPhases.TEMPLATE)
    ) {
      let tempTemplateQuestionsList = deepClone(templateQuestionsList);
      let filteredCurrentAnswerList = [];
      let answersObject = {};
      if (isExperienceRangeQs()) {
        let tempTemplateQsAnswersList = deepClone(templateQsAnswersList);
        handleExperienceRangeAnswer(
          tempTemplateQsAnswersList,
          currentAnswerList
        );
        setTemplateQsAnswersList(tempTemplateQsAnswersList);
        // dispatch(
        //   setUChatbotAnswers({
        //     ...uChatbotAnswers,
        //     templateAnswers: tempTemplateQsAnswersList,
        //   })
        // );
      } else {
        // no of l2 qs asked will depend on no of selected l1 options
        if (currentQuestionObj.level === levels.LEVEL1) {
          // check if the option selected in l1 has options in l2
          filteredCurrentAnswerList = currentAnswerList.filter((answer) => {
            return Object.keys(
              templateQuestionOptionsObject[
                templateQuestionsList[currentQuestionIndex + 1]["question"]
              ]
            ).includes(answer);
          });
          setSelectedL1Options(filteredCurrentAnswerList);
          // Insert multiple L2 questions if multiple L1 options selected
          if (filteredCurrentAnswerList.length > 1) {
            for (
              let index = 0;
              index < filteredCurrentAnswerList.length - 1;
              index++
            ) {
              tempTemplateQuestionsList.splice(
                currentQuestionIndex + 1,
                0,
                templateQuestionsList[currentQuestionIndex + 1]
              );
            }
          }
          // Handle only other option selected case
          else if (filteredCurrentAnswerList.length === 0) {
            // Remove role/subdomain(L2) qs
            tempTemplateQuestionsList.splice(currentQuestionIndex + 1, 1);
          }
        }

        currentAnswerList.forEach((answer, index) => {
          // Insert experience range qs after each template qs
          tempTemplateQuestionsList.splice(
            currentQuestionIndex + (index + 1),
            0,
            {
              question: questionToAsk.experience,
              ansType: renderAnswerTypes.RADIO_BUTTON,
              anyN: 1,
              field: answer,
            }
          );

          // Save template answer in answersObject with experience range as null by default
          answersObject[answer] = null;
          // Other option selected logic
          if (!Object.keys(templateOptionsIdObj).includes(answer)) {
            // creates deep copy of templateOptionsIdObj
            let tempTemplateOptionsIdObj = deepClone(templateOptionsIdObj);
            // find object of other option selected containing the id
            let otherObjOption = currentQuestionOptionsIdList.find((obj) =>
              isNotEmpty(obj["Other"])
            );
            // add the other option input box answer and its other option id to templateOptionsIdObj
            tempTemplateOptionsIdObj[answer] = {
              id: otherObjOption["Other"],
              level: currentQuestionObj.level,
            };
            setTemplateOptionsIdObj(tempTemplateOptionsIdObj);
          }
        });
        setTemplateQsAnswersList([...templateQsAnswersList, answersObject]);
        // dispatch(
        //   setUChatbotAnswers({
        //     ...uChatbotAnswers,
        //     templateAnswers: [...templateQsAnswersList, answersObject],
        //   })
        // );
        setTemplateQuestionsList(tempTemplateQuestionsList);
      }
      setCurrentQuestionIndex((prevState) => prevState + 1);
    } else if (
      uChatbotFlags.templateQsCompletedFlag === true &&
      uChatbotFlags.defaultQsCompletedFlag === false
    ) {
      if (isExperienceRangeQs()) {
        let tempDefaultQsAnswersList = deepClone(defaultQsAnswersList);
        handleExperienceRangeAnswer(
          tempDefaultQsAnswersList,
          currentAnswerList
        );
        setDefaultQsAnswersList(tempDefaultQsAnswersList);
      } else {
        // Handle default qs submitted answers
        let tempObject = {};
        let tempDefaultQsList = deepClone(defaultQuestionsList);
        currentAnswerList.forEach((answer, index) => {
          tempObject = { ...tempObject, ...{ [answer]: true } };
          if (currentQuestionObj.tag === UChatbotQs.TOP_SKILLS_TAG) {
            // Insert experience range qs after top 5 skills qs
            tempDefaultQsList.splice(currentQuestionIndex + (index + 1), 0, {
              question: questionToAsk.experience,
              ansType: renderAnswerTypes.RADIO_BUTTON,
              anyN: 1,
              field: answer,
            });
          }
        });
        setDefaultQuestionsList(tempDefaultQsList);
        setDefaultQsAnswersList([...defaultQsAnswersList, tempObject]);
      }
      setCurrentQuestionIndex((prevState) => prevState + 1);
    } else if (uChatbotPhase === uChatbotPhases.SCREENING) {
      // handle hr and custom qs submitted answers
      let tempScreeningAnswerObj = {};
      if (!isNotEmpty(currentQuestionObj.multiLevelOptions)) {
        tempScreeningAnswerObj[currentQuestionObj.id] = [currentAnswerList[0]];
      } else {
        tempScreeningAnswerObj[currentQuestionObj.id] = currentAnswerList;
      }
      if (isOtherLocation && isOtherLocation === true) {
        submitScreeningQsAnswer(
          tempScreeningAnswerObj,
          currentQuestionObj.id,
          true
        );
      } else {
        submitScreeningQsAnswer(tempScreeningAnswerObj, currentQuestionObj.id);
      }
      if (uChatbotFlags.hrQsCompletedFlag === false) {
        setHrQsAnswersObj({ ...hrQsAnswersObj, ...tempScreeningAnswerObj });
      } else if (uChatbotFlags.customQsCompletedFlag === false) {
        setCustomQsAnswersObj({
          ...customQsAnswersObj,
          ...tempScreeningAnswerObj,
        });
      }
      setCurrentQuestionIndex((prevState) => prevState + 1);
    }
    setCurrentQuestionObj(null);
  };

  return (
    <div>
      <div className={"chatbot-container"}>
        {isNotEmpty(uChatbotFlags) &&
        uChatbotFlags?.defaultQsCompletedFlag === true &&
        isNotEmpty(uChatbotAnswers.screeningAnswers) &&
        isNotEmpty(hrQuestionsList) &&
        startHrSectionFlag === false &&
        Object.keys(uChatbotAnswers.screeningAnswers).length === 0 ? (
          <UChatbotStartHrSection handleClick={setStartHrSectionFlag} />
        ) : (
          getQuestionAnswerJSX()
        )}
        <PoweredByLogo />
      </div>
    </div>
  );
};
export default UniversalChatbotSections;
