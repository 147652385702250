import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isNotEmpty } from "../../../utils/Helper";
import { urls } from "../../../utils/routes";
import ResumeUpload from "../../common/ResumeUpload";

const ResumeUploadWrapper = () => {
  const {
    jobCandidateMapping,
    jobDetail,
    candidateInterest,
    candidateInterestDate,
  } = useSelector((state) => state);

  const candidateName = isNotEmpty(jobCandidateMapping)
    ? jobCandidateMapping.displayName
    : "";
  const companyName =
    isNotEmpty(jobDetail) && isNotEmpty(jobDetail.company)
      ? jobDetail.company.companyName
      : "";

  useEffect(() => {
    if (
      !(jobCandidateMapping.chatbotStatus === "Complete") &&
      candidateInterest
    ) {
      document.location = `#${urls.CHATBOT}`;
    } else if (
      !(jobCandidateMapping.chatbotStatus === "Complete") &&
      !candidateInterest &&
      !isNotEmpty(candidateInterestDate)
    ) {
      document.location = `#${urls.CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.DECLINE_THANKYOU}`;
    }
  }, []);

  return (
    <ResumeUpload candidateName={candidateName} companyName={companyName} />
  );
};

export default ResumeUploadWrapper;
