import { useSelector } from "react-redux";
import { isNotEmpty, StringToHtml } from "../../../utils/Helper";
import { questionObjType } from "../../../types";
import {
  levels,
  uChatbotPhases,
  UChatbotQs,
} from "../../../utils/UChatbotConstants";
import { useEffect, useState } from "react";

const Question = ({
  questionObj,
  questionIndex,
  selectedL1Options,
  templateQuestionLevel,
  l2QuestionIndex,
}) => {
  const { uChatbotDetails, uChatbotFlags, uChatbotPhase } = useSelector(
    (state) => state
  );
  const { companyName, questionToAsk, candidateFirstName } = uChatbotDetails;
  const [questionJSX, setQuestionJSX] = useState(<div></div>);

  const maxSelection = questionObj?.anyNText
    ? `(${questionObj.anyNText.replace("$anyN", questionObj.anyN)})`
    : null;

  useEffect(() => {
    let tempQuestionJSX = <div></div>;
    const isExperienceRangeQs = () => {
      return questionObj?.question?.includes(UChatbotQs.EXPERIENCE_RANGE_QS);
    };

    if (isExperienceRangeQs()) {
      if (
        (uChatbotPhase === uChatbotPhases.INVITED ||
          uChatbotPhase === uChatbotPhases.TEMPLATE) &&
        templateQuestionLevel === levels.LEVEL2
      ) {
        tempQuestionJSX = `${questionObj?.question.replace(
          "$label",
          `${questionObj?.field}`
        )}`;
        tempQuestionJSX = `${tempQuestionJSX} under <b>${
          selectedL1Options[l2QuestionIndex - 1]
        }</b>?`;
      } else {
        tempQuestionJSX = `${questionObj?.question.replace(
          "$label",
          `${questionObj?.field}?`
        )}`;
      }
    } else if (isNotEmpty(questionObj?.screeningQuestions)) {
      tempQuestionJSX = questionObj?.question.replace(
        "`$companyName`",
        companyName
      );
    } else if (questionObj?.fullQsText) {
      tempQuestionJSX = questionObj.fullQsText;
    }
    setQuestionJSX(tempQuestionJSX);
  }, [
    companyName,
    uChatbotPhase,
    questionObj,
    selectedL1Options,
    templateQuestionLevel,
    l2QuestionIndex,
  ]);

  return (
    <>
      {uChatbotFlags.hrQsCompletedFlag === true && questionIndex === 0 && (
        <h4 className="q-text mb-4" style={{ display: "inline-block" }}>
          {StringToHtml(
            questionToAsk.hrCompletion.replace(
              "$candidateName",
              candidateFirstName
            ),
            "p"
          )}
        </h4>
      )}
      <h4 className="q-text mr-1" style={{ display: "inline-block" }}>
        {StringToHtml(questionJSX)}
      </h4>
      <p style={{ display: "inline-block" }}>
        <i>{maxSelection}</i>
      </p>
    </>
  );
};
export default Question;

Question.propTypes = {
  questionObj: questionObjType,
};
