import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isNotEmpty } from "../../utils/Helper";
import { urls } from "../../utils/routes";
import ResumeUpload from "../common/ResumeUpload";

const UChatbotResumeWrapper = () => {
  const {
    candidateInterest,
    candidateInterestDate,
    uChatbotPhase,
    uChatbotDetails,
  } = useSelector((state) => state);
  const { candidateFirstName, candidateLastName, companyName } =
    uChatbotDetails;

  useEffect(() => {
    if (candidateInterest && uChatbotPhase !== "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (
      !(uChatbotPhase === "Complete") &&
      !candidateInterest &&
      !isNotEmpty(candidateInterestDate)
    ) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_CONSENT}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.DECLINE_THANKYOU}`;
    }
  }, [uChatbotPhase, candidateInterest, candidateInterestDate]);

  return (
    <ResumeUpload
      candidateName={`${candidateFirstName} ${candidateLastName}`}
      companyName={companyName}
    />
  );
};

export default UChatbotResumeWrapper;
