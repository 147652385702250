import { useSelector } from "react-redux";
import oopsImg from "./assets/images/oops-img.png";
import PoweredByLogo from "./components/common/PoweredByLogo";

const PageNotFound = () => {
  const { chatbotErrorMessage } = useSelector((state) => state);
  return (
    <div
      id="main-content"
      className="container-fluid mx-auto justify-content-center"
    >
      <div className="row">
        <div className="w-100">
          <div className="row">
            <div className="col-lg-4 offset-lg-2">
              <h1 className="not-found-error-header">Oops!</h1>
              <p className="not-found-error-text">{chatbotErrorMessage}</p>
            </div>
            <div className="col-lg-4 offset-lg-2 oops-img">
              <img src={oopsImg} alt="oops" />
            </div>
          </div>
        </div>
      </div>
      <PoweredByLogo />
    </div>
  );
};

export default PageNotFound;
