import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isNotEmpty, StringToHtml } from "../../utils/Helper";
import PoweredByLogo from "../common/PoweredByLogo";
import litmusLogo from "../../assets/images/logo-litmusBlox.svg";
import { urls } from "../../utils/routes";

import ButtonOrange from "../common/ButtonOrange";
import { showLoader } from "../../actions/LoaderAction";

const UniversalChatbotHome = () => {
  const {
    candidateInterest,
    candidateInterestDate,
    uChatbotPhase,
    uChatbotDetails,
  } = useSelector((state) => state);

  const {
    candidateFirstName,
    candidateLastName,
    companyName,
    companyLogo,
    questionToAsk,
  } = uChatbotDetails;

  const dispatch = useDispatch();
  const [knowMoreAndApply, setKnowMoreAndApply] = useState("");
  const [renderHomeJSX, setRenderHomeJSX] = useState(false);

  useEffect(() => {
    dispatch(showLoader(true));
  }, []);

  useEffect(() => {
    let string1 = isNotEmpty(questionToAsk)
      ? `<b className="c-name">Hi ${candidateFirstName} ${candidateLastName}</b><br /><br />`
      : "";
    let string2 = isNotEmpty(questionToAsk)
      ? questionToAsk?.knowMoreAndApply.replace(
          "$companyName",
          `<b>${companyName}</b>`
        )
      : "";
    setKnowMoreAndApply(string1.concat(string2));
  }, [questionToAsk]);

  useEffect(() => {
    if (candidateInterest && uChatbotPhase !== "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_SECTIONS}`;
    } else if (!candidateInterest && isNotEmpty(candidateInterestDate)) {
      document.location = `#${urls.UNIVERSAL_CHATBOT_DECLINE_THANKYOU}`;
    } else if (candidateInterest && uChatbotPhase === "complete") {
      document.location = `#${urls.UNIVERSAL_CHATBOT_UPLOAD_RESUME}`;
    } else {
      setTimeout(() => {
        showLoader(false);
        setRenderHomeJSX(true);
      }, 500);
    }
  }, [candidateInterest, candidateInterestDate, uChatbotPhase]);

  if (renderHomeJSX) {
    return (
      <div className="chatbot-container">
        <div className="company-logo">
          <img
            src={
              isNotEmpty(companyLogo)
                ? process.env.REACT_APP_BASE + companyLogo
                : litmusLogo
            }
            width={200}
            alt={"Logo"}
          />
        </div>
        <div className="home-text-universal">
          {StringToHtml(knowMoreAndApply, "p")}
        </div>
        <ButtonOrange
          btnText={"I wish to know more"}
          onClick={() =>
            (document.location = `#${urls.UNIVERSAL_CHATBOT_CONSENT}`)
          }
        />
        <PoweredByLogo />
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default UniversalChatbotHome;
