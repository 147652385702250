import React, {useState} from 'react';
import './YesNoRadioButton.css';
import cross from '../../../assets/images/gray-cross.png'
import check from '../../../assets/images/gray-check.png'
import crossGreen from '../../../assets/images/cross-green.png'
import checkGreen from '../../../assets/images/check-green.png'
import {isNotEmpty} from "../../../utils/Helper";
import OKButton from "../../OKButton";
import { func } from 'prop-types';

const YesNoRadioButton = ({handleSubmit}) => {
    const [value, setValue] = useState("");
    return (
        <div>
            <span onClick={() => setValue('Yes')}>
                <label className={value === "Yes" ? 'label__checked' : 'label__unChecked'} >
                    <img
                        src={value === "Yes" ? checkGreen : check}
                        alt={value}
                        className={value === "Yes" ? 'img__checked' : 'img__unchecked'}
                    />
                    Yes
                    </label>
            </span>
            <span onClick={() => setValue('No')}>
                <label className={value === "No" ? 'label__checked' : 'label__unChecked'}  >
                    <img
                        src={value === "No" ? crossGreen : cross}
                        alt={value}
                        className={ value === "No" ? 'img__checked' : 'img__unchecked'}
                    />
                    No
                    </label>
            </span>
            <br />
            <OKButton disabled={!isNotEmpty(value)} onClick={()=>{handleSubmit(value); setValue("");}} />
        </div>
    )
};

export default YesNoRadioButton

YesNoRadioButton.propTypes = {
  handleSubmit: func.isRequired,
};
