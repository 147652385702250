import { useEffect, useState } from "react";
import { isNotEmpty } from "../../utils/Helper";
import OKButton from "../OKButton";
import OtherOptionTextInput from "./OtherOptionTextInput/OtherOptionTextInput";

const TopSkillsTextBoxes = ({ handleSubmit }) => {
  const [disabledFlag, setDisabledFlag] = useState(true);

  const [skill1, setSkill1] = useState("");
  const [skill2, setSkill2] = useState("");
  const [skill3, setSkill3] = useState("");
  const [skill4, setSkill4] = useState("");
  const [skill5, setSkill5] = useState("");

  useEffect(() => {
    if (isNotEmpty(skill1)) {
      setDisabledFlag(false);
    } else {
      setDisabledFlag(true);
    }
  }, [skill1]);

  const handleChange = (inputValue, changeSkill) => {
    // remove character if not alphanumerics or #.+
    let validatedInputValue = inputValue.replace(/[^a-zA-Z0-9.#+ ]/g, "");
    if (validatedInputValue.length === 1) {
      validatedInputValue = validatedInputValue.trim();
    }
    switch (changeSkill) {
      case "skill1":
        setSkill1(validatedInputValue);
        break;
      case "skill2":
        setSkill2(validatedInputValue);
        break;
      case "skill3":
        setSkill3(validatedInputValue);
        break;
      case "skill4":
        setSkill4(validatedInputValue);
        break;
      case "skill5":
        setSkill5(validatedInputValue);
        break;
      default:
        break;
    }
  };

  const handleJoinSkills = () => {
    let allSkills = skill1;
    allSkills = isNotEmpty(skill2) ? allSkills.concat("%$", skill2) : allSkills;
    allSkills = isNotEmpty(skill3) ? allSkills.concat("%$", skill3) : allSkills;
    allSkills = isNotEmpty(skill4) ? allSkills.concat("%$", skill4) : allSkills;
    allSkills = isNotEmpty(skill5) ? allSkills.concat("%$", skill5) : allSkills;
    return allSkills;
  };

  const handleOkButton = () => {
    let joinedSkills = handleJoinSkills();
    handleSubmit(joinedSkills);
    setSkill1("");
    setSkill2("");
    setSkill3("");
    setSkill4("");
    setSkill5("");
    setDisabledFlag(true);
  };

  return (
    <>
      <span className="q-text">Skill 1</span>
      <OtherOptionTextInput
        inputValue={skill1}
        handleChange={(inputValue) => handleChange(inputValue, "skill1")}
      />
      <br />
      <span className="q-text">Skill 2</span>
      <OtherOptionTextInput
        inputValue={skill2}
        handleChange={(inputValue) => handleChange(inputValue, "skill2")}
      />
      <br />
      <span className="q-text">Skill 3</span>
      <OtherOptionTextInput
        inputValue={skill3}
        handleChange={(inputValue) => handleChange(inputValue, "skill3")}
      />
      <br />
      <span className="q-text">Skill 4</span>
      <OtherOptionTextInput
        inputValue={skill4}
        handleChange={(inputValue) => handleChange(inputValue, "skill4")}
      />
      <br />
      <span className="q-text">Skill 5</span>
      <OtherOptionTextInput
        inputValue={skill5}
        handleChange={(inputValue) => handleChange(inputValue, "skill5")}
      />
      <br />
      <OKButton
        onClick={() => {
          handleOkButton();
        }}
        disabled={disabledFlag}
      />
    </>
  );
};

export default TopSkillsTextBoxes;
